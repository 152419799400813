import { styled } from 'configs/stitches';

const TextArea = styled('textarea', {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$2',
  px: '$2',
  py: '$2',
  color: '$white',
  border: 'none',
  boxShadow: `0 0 0 1px $gray6`,
  backgroundColor: '$gray1',
  '&:focus': {
    boxShadow: `0 0 0 2px $colors$gray6`,
    border: 'none',
    outline: 'none',
  },
  variants: {
    block: {
      true: {
        display: 'block',
        width: '100%',
      },
    },
  },
});

export default TextArea;
