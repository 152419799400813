import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const ChatIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.269 5.3999H13.309C16.818 5.3999 18.578 7.1599 18.578 10.6689V15.0689C18.578 15.3549 18.567 15.6299 18.534 15.8939C18.281 18.8639 16.532 20.3379 13.309 20.3379H12.869C12.594 20.3379 12.33 20.4699 12.165 20.6899L10.845 22.4499C10.262 23.2309 9.316 23.2309 8.733 22.4499L7.413 20.6899C7.27 20.5029 6.951 20.3379 6.709 20.3379H6.269C2.76 20.3379 1 19.4689 1 15.0689V10.6689C1 7.4459 2.485 5.6969 5.444 5.4439C5.708 5.4109 5.983 5.3999 6.269 5.3999ZM4.85 13.0999C4.85 13.7049 5.334 14.1999 5.95 14.1999C6.555 14.1999 7.05 13.7049 7.05 13.0999C7.05 12.4949 6.555 11.9999 5.95 11.9999C5.345 11.9999 4.85 12.4949 4.85 13.0999ZM9.789 14.1999C9.173 14.1999 8.689 13.7049 8.689 13.0999C8.689 12.4949 9.184 11.9999 9.789 11.9999C10.394 11.9999 10.889 12.4949 10.889 13.0999C10.889 13.7049 10.405 14.1999 9.789 14.1999ZM12.539 13.0999C12.539 13.7049 13.023 14.1999 13.639 14.1999C14.244 14.1999 14.739 13.7049 14.739 13.0999C14.739 12.4949 14.244 11.9999 13.639 11.9999C13.034 11.9999 12.539 12.4949 12.539 13.0999Z"
        fill={color}
      />
    <path
        d="M22.9782 6.269V10.669C22.9782 12.869 22.2962 14.365 20.9322 15.19C20.6022 15.388 20.2172 15.124 20.2172 14.739L20.2282 10.669C20.2282 6.269 17.7092 3.75 13.3092 3.75L6.61027 3.761C6.22527 3.761 5.96127 3.376 6.15927 3.046C6.98427 1.682 8.48027 1 10.6692 1H17.7092C21.2182 1 22.9782 2.76 22.9782 6.269Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(ChatIcon);
