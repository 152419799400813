import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { styled } from 'configs/stitches';

import Spinner from './Spinner';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, loading, ...props }, ref) => (
    <button className={className} {...props} ref={ref} data-state={loading && 'loading'}>
      {loading && <Spinner color="gray" />}
      {children}
    </button>
  )
);

export default styled(Button, {
  all: 'unset',
  userSelect: 'none',
  cursor: 'pointer',
  flexShrink: 0,
  textAlign: 'center',
  overflow: 'hidden',
  color: '$gray12',
  boxSizing: 'border-box',
  fontWeight: 500,
  display: 'inline-block',
  '&:disabled': {
    cursor: 'not-allowed',
  },
  '&:hover': {
    color: '$gray12',
  },
  '&[data-state=loading]': {
    position: 'relative',
    paddingLeft: 'calc($sizes$md + $2 + $2)',
    cursor: 'wait',
    [`& ${Spinner}`]: {
      position: 'absolute',
      left: '$2',
      top: 'calc(50% - $sizes$md / 2)',
    },
  },
  svg: {
    width: '1.5em',
    verticalAlign: 'bottom',
  },
  variants: {
    block: {
      true: {
        display: 'block',
        width: '100%',
        px: 0,
      },
    },
    variant: {
      default: {
        borderWidth: 1,
        borderStyle: 'solid',
      },
      rounded: {
        borderRadius: '$10 !important',
      },
      link: {
        background: 'transparent !important',
        px: 3,
        py: 0,
      },
      outline: {},
      'rounded-outline': {
        borderRadius: '$10',
      },
    },
    size: {
      sm: {
        borderRadius: '$1',
        px: '$2',
        py: '$1',
        fontSize: '$sm',
        minHeight: '$sm',
        borderWidth: '1px !important',
      },
      md: {
        borderRadius: '$2',
        minHeight: '$md',
        px: '$2',
        py: '$1',
        fontSize: '$1',
      },
      lg: {
        borderRadius: '$3',
        minHeight: '$lg',
        px: '$3',
        py: '$2',
        fontSize: '$2',
      },
    },
    color: {
      primary: {
        borderColor: '$green9',
        backgroundColor: '$green9',
        '&:hover': {
          backgroundColor: '$green10',
        },
        '&:active': {
          backgroundColor: '$green9',
          boxShadow: '0 0 0 2px $colors$green7',
        },
        '&:focus': {
          boxShadow: '0 0 0 2px $colors$green7',
        },
        '&:disabled': {
          backgroundColor: '$green3 !important',
          // boxShadow: 'inset 0 0 1px 1px $colors$green7',
          borderColor: '$green4',
          color: '$gray11',
          [`& ${Spinner}`]: {
            border: '4px solid $green4',
            borderTopColor: '$green8',
          },
        },
        [`& ${Spinner}`]: {
          border: '4px solid $green11',
          borderTopColor: '$green12',
        },
      },
      secondary: {
        borderColor: '$gray6',
        backgroundColor: '$gray5',
        '&:hover': {
          backgroundColor: '$gray6',
        },
        '&:active': {
          backgroundColor: '$gray5',
          boxShadow: '0 0 0 2px $colors$gray7',
        },
        '&:focus': {
          boxShadow: '0 0 0 2px $colors$gray7',
        },
        '&:disabled': {
          backgroundColor: '$gray4',
          // boxShadow: 'inset 0 0 1px 1px $colors$gray5',
          borderColor: '$gray5',
          color: '$gray9',
        },
      },
      danger: {
        borderColor: '$red9',
        backgroundColor: '$red9',
        '&:hover': {
          backgroundColor: '$red10',
        },
        '&:active': {
          backgroundColor: '$red9',
          boxShadow: '0 0 0 2px $colors$red7',
        },
        '&:focus': {
          boxShadow: '0 0 0 2px $colors$red7',
        },
        '&:disabled': {
          backgroundColor: '$red3',
          // boxShadow: 'inset 0 0 1px 1px $colors$red5',
          borderColor: '$red4',
          color: '$red11',
        },
      },
      white: {
        color: '$gray2',
        backgroundColor: '$white',
        borderColor: '$gray11',
        '&:hover': {
          color: '$gray1',
        },
        '&:disabled': {
          backgroundColor: '$gray11',
          borderColor: '$gray5',
          color: '$gray7',
        },
      },
      warning: {},
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'md',
    color: 'primary',
  },
  compoundVariants: [
    {
      variant: 'rounded-outline',
      color: 'primary',
      css: {
        borderRadius: '$10',
        backgroundColor: '$green3',
        borderColor: '$green7',
        border: '1px solid $green7',
        '&:hover': {
          borderColor: '$green8',
          backgroundColor: '$green4',
        },
        '&:focus': {
          boxShadow: '0 0 0 1px $colors$green7',
        },
        '&:active': {
          borderColor: '$green8',
          backgroundColor: '$green5',
          boxShadow: '0 0 0 1px $colors$green7',
        },
      },
    },
    {
      variant: 'rounded-outline',
      color: 'danger',
      css: {
        borderRadius: '$10',
        backgroundColor: '$red3',
        borderColor: '$red7',
        border: '1px solid $red7',
        '&:hover': {
          borderColor: '$red8',
          backgroundColor: '$red4',
        },
        '&:focus': {
          boxShadow: '0 0 0 1px $colors$red7',
        },
        '&:active': {
          borderColor: '$red8',
          backgroundColor: '$red5',
          boxShadow: '0 0 0 1px $colors$red7',
        },
      },
    },
    {
      variant: 'outline',
      color: 'primary',
      css: {
        backgroundColor: '$green3',
        borderColor: '$green7',
        border: '2px solid $green7',
        '&:hover': {
          borderColor: '$green8',
          backgroundColor: '$green4',
        },
        '&:focus': {
          boxShadow: '0 0 0 1px $colors$green7',
        },
        '&:active': {
          borderColor: '$green8',
          backgroundColor: '$green5',
          boxShadow: '0 0 0 1px $colors$green7',
        },
        '&:disabled': {
          backgroundColor: '$green3 !important',
          boxShadow: 'none',
          color: '$gray11',
          borderColor: '$green4',
        },
        [`& ${Spinner}`]: {
          border: '4px solid $green4',
          borderTopColor: '$green8',
        },
      },
    },
    {
      variant: 'outline',
      color: 'danger',
      css: {
        backgroundColor: '$red3',
        borderColor: '$red7',
        border: '2px solid $red7',
        '&:hover': {
          borderColor: '$red8',
          backgroundColor: '$red4',
        },
        '&:focus': {
          boxShadow: '0 0 0 1px $colors$red7',
        },
        '&:active': {
          borderColor: '$red8',
          backgroundColor: '$red5',
          boxShadow: '0 0 0 1px $colors$red7',
        },
        '&:disabled': {
          backgroundColor: '$red3 !important',
          boxShadow: 'none',
          color: '$gray11',
          borderColor: '$red4',
        },
        [`& ${Spinner}`]: {
          border: '4px solid $red4',
          borderTopColor: '$red8',
        },
      },
    },
    {
      variant: 'outline',
      color: 'warning',
      css: {
        backgroundColor: '$orange3',
        borderColor: '$orange7',
        border: '2px solid $orange7',
        '&:hover': {
          borderColor: '$orange8',
          backgroundColor: '$orange4',
        },
        '&:focus': {
          boxShadow: '0 0 0 1px $colors$orange7',
        },
        '&:active': {
          borderColor: '$orange8',
          backgroundColor: '$orange5',
          boxShadow: '0 0 0 1px $colors$orange7',
        },
        '&:disabled': {
          backgroundColor: '$orange3 !important',
          boxShadow: 'none',
          color: '$gray11',
          borderColor: '$orange4',
        },
        [`& ${Spinner}`]: {
          border: '4px solid $orange4',
          borderTopColor: '$orange8',
        },
      },
    },
    {
      variant: 'outline',
      color: 'secondary',
      css: {
        backgroundColor: '$gray3',
        borderColor: '$gray7',
        border: '2px solid $gray7',
        '&:hover': {
          borderColor: '$gray8',
          backgroundColor: '$gray4',
        },
        '&:focus': {
          boxShadow: '0 0 0 1px $colors$gray7',
        },
        '&:active': {
          borderColor: '$gray8',
          backgroundColor: '$gray5',
          boxShadow: '0 0 0 1px $colors$gray7',
        },
      },
    },
    {
      variant: 'link',
      color: 'primary',
      css: {
        color: '$green9',
        '&:hover': {
          color: '$green11',
        },
      },
    },
    {
      variant: 'link',
      color: 'secondary',
      css: {
        color: '$gray9',
        '&:hover': {
          color: '$gray11',
        },
      },
    },
    {
      variant: 'link',
      color: 'danger',
      css: {
        color: '$red9',
        '&:hover': {
          color: '$red11',
        },
      },
    },
  ],
});

Button.displayName = 'Button';
