import React, { ReactNode } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import * as DropDownMenu from '@radix-ui/react-dropdown-menu';

import { keyframes, styled } from 'configs/stitches';

import { Box } from '.';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const contentStyle = {
  minWidth: 220,
  backgroundColor: '$gray5',
  borderRadius: '$2',
  padding: '$1',
  border: '1px solid $gray4',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
};

const DropDownContent = styled(DropDownMenu.Content, contentStyle);
const DropDownSubContent = styled(DropDownMenu.SubContent, contentStyle);

const itemStyle = {
  all: 'unset',
  // fontSize: 13,
  lineHeight: 1,
  color: '$gray12',
  borderRadius: 3,
  display: 'flex',
  alignItems: 'center',

  padding: '0 5px',
  position: 'relative',
  py: '$1',
  userSelect: 'none1',
  cursor: 'pointer !important',

  '&[data-disabled]': {},

  '&:focus': {
    backgroundColor: '$gray1',
    // color: '$,
  },
  variants: {
    size: {
      sm: {
        fontSize: '$sm',
      },
    },
  },
};
const DropDownItem = styled(DropDownMenu.Item, itemStyle);
const SubTrigger = styled(DropDownMenu.SubTrigger, itemStyle);

const DropwDownCheckBoxItem = styled(DropDownMenu.DropdownMenuCheckboxItem, {});
const DropDownItemIndicator = styled(DropDownMenu.ItemIndicator, {});

const DropwDownArow = styled(DropDownMenu.Arrow, {
  fontSize: '$xs',
  fill: '$gray5',
});

interface DropDownProps {
  className?: string;
  trigger: ReactNode;
  sideOffset?: number;
  alignOffset?: number;
  side?: DropDownMenu.DropdownMenuContentProps['side'];
  align?: DropDownMenu.DropdownMenuContentProps['align'];
  children: React.ReactNode;
}

const DropDown: React.FC<DropDownProps> = ({
  trigger,
  className,
  sideOffset = 5,
  alignOffset,
  side,
  align,
  children,
}) => (
  <Box className={className}>
    <DropDownMenu.Root>
      <DropDownMenu.Trigger asChild>{trigger}</DropDownMenu.Trigger>
      <DropDownMenu.Portal>
        <DropDownContent
          side={side}
          align={align}
          sideOffset={sideOffset}
          alignOffset={alignOffset}
        >
          {children}
          <DropwDownArow />
        </DropDownContent>
      </DropDownMenu.Portal>
    </DropDownMenu.Root>
  </Box>
);

export type CheckBoxProps = DropDownMenu.DropdownMenuCheckboxItemProps & {
  className?: string;
  icon: any;
};

const CheckBox: React.FC<CheckBoxProps> = ({ children, icon, ...props }) => (
  <DropwDownCheckBoxItem {...props}>
    <DropDownItemIndicator>{icon || <CheckIcon />}</DropDownItemIndicator>
    {children}
  </DropwDownCheckBoxItem>
);

interface SubProps {
  trigger: JSX.Element;
  children: React.ReactNode;
}
const Sub: React.FC<SubProps> = ({ trigger, children }) => (
  <DropDownMenu.Sub>
    <SubTrigger asChild>{trigger}</SubTrigger>
    <DropDownMenu.Portal>
      <DropDownSubContent>
        {children}
        <DropwDownArow />
      </DropDownSubContent>
    </DropDownMenu.Portal>
  </DropDownMenu.Sub>
);

export default Object.assign(DropDown, {
  Item: DropDownItem,
  CheckBox,
  Sub,
});
