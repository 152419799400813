import React from 'react';
import {
  Root,
  Content,
  Trigger,
  Arrow,
  TooltipProps as RootProps,
  TooltipContentProps,
  Portal,
} from '@radix-ui/react-tooltip';

import { keyframes, styled } from 'configs/stitches';

type TooltipProps = RootProps &
  TooltipContentProps & {
    className?: string;
    content?: React.ReactNode;
  };
export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  delayDuration = 300,
  open,
  onOpenChange,
  align,
  side,
  sideOffset = 5,
}) => {
  if (!content) {
    return <>{children}</>;
  }

  if (typeof children !== 'object') {
    children = <span>{children}</span>;
  }

  return (
    <Root delayDuration={delayDuration} open={open} onOpenChange={onOpenChange}>
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <StyledContent side={side} sideOffset={sideOffset} align={align}>
          <StyledArrow />
          {content}
        </StyledContent>
      </Portal>
    </Root>
  );
};

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(Content, {
  borderRadius: '$2',
  padding: '$1 $2',
  fontSize: '$sm',
  color: '$gray3',
  backgroundColor: '$gray11',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(Arrow, {
  fill: '$gray11',
});
