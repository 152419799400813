import React from 'react';
import { createPortal } from 'react-dom';

import { ToastProvider } from './Toast';

const ToastContainer = () =>
  createPortal(
    <div>
      <ToastProvider position="top-right" />
      <ToastProvider position="top-left" />
      <ToastProvider position="bottom-right" />
      <ToastProvider position="bottom-left" />
    </div>,
    document.body
  );

export default ToastContainer;
