import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const TelegramIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10.7889C1.19318 10.3942 1.54754 10.1875 1.93444 10.0382C3.43805 9.45807 4.94543 8.88759 6.45221 8.31556C11.7679 6.29755 17.084 4.28044 22.3995 2.26231C22.5327 2.21176 22.6664 2.16436 22.8082 2.14743C23.5116 2.06326 23.9713 2.47164 23.9984 3.20547C24.0171 3.70984 23.8629 4.18807 23.7591 4.6723C22.8697 8.82534 21.9742 12.9771 21.0799 17.129C20.8739 18.0855 20.6659 19.0415 20.4585 19.9977C20.4111 20.2162 20.3404 20.4266 20.2275 20.6211C19.9731 21.0597 19.5802 21.222 19.0854 21.0941C18.8367 21.0299 18.6105 20.9226 18.4022 20.771C16.7556 19.5725 15.1049 18.3797 13.4627 17.1755C13.2979 17.0547 13.2111 17.0763 13.0762 17.2057C12.2425 18.0057 11.3998 18.7966 10.563 19.5935C10.3563 19.7904 10.1186 19.9149 9.83518 19.9576C9.70777 19.9767 9.64595 19.958 9.65763 19.8017C9.74856 18.5883 9.83044 17.3741 9.91652 16.1603C9.94797 15.7163 9.98786 15.2727 10.0135 14.8284C10.021 14.6981 10.0735 14.6144 10.169 14.5294C13.3506 11.6995 16.5298 8.86692 19.7093 6.03453C19.7593 5.98992 19.8101 5.94573 19.857 5.89785C19.9308 5.82241 20.0125 5.73996 19.956 5.62615C19.9002 5.51365 19.782 5.52547 19.6779 5.53188C19.4776 5.5443 19.3068 5.63601 19.1407 5.73913C15.1978 8.18607 11.254 10.6313 7.31447 13.0835C7.12758 13.1998 6.9749 13.2252 6.76057 13.1578C5.12321 12.6419 3.48099 12.1411 1.84034 11.6354C1.49873 11.5302 1.18988 11.3781 1 11.0562V10.7889Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(TelegramIcon);
