import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const PassPortIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      ref={forwardedRef}
      {...props}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 0C3.67383 0 3 0.673828 3 1.5V22.5C3 23.3262 3.67383 24 4.5 24H20.5C21.3262 24 22 23.3262 22 22.5V1.5C22 0.673828 21.3262 0 20.5 0H4.5ZM12.5 4C15.5332 4 18 6.4668 18 9.5C18 12.5332 15.5332 15 12.5 15C9.4668 15 7 12.5332 7 9.5C7 6.4668 9.4668 4 12.5 4ZM10.5156 9C10.6152 7.06445 11.2793 5.64062 12 5.17188V9H10.5156ZM13 5.17188V9H14.4844C14.3848 7.06445 13.7207 5.64062 13 5.17188ZM8.03125 9C8.19922 7.49609 9.11328 6.21484 10.3906 5.53125C9.89844 6.41211 9.57812 7.61719 9.51562 9H8.03125ZM14.6094 5.53125C15.1016 6.41211 15.4219 7.61719 15.4844 9H16.9688C16.8008 7.49609 15.8887 6.21484 14.6094 5.53125ZM10.3906 13.4688C9.11328 12.7852 8.19922 11.5039 8.03125 10H9.51562C9.57812 11.3828 9.89844 12.5879 10.3906 13.4688ZM10.5156 10C10.6152 11.9355 11.2793 13.3594 12 13.8281V10H10.5156ZM13 13.8281V10H14.4844C14.3848 11.9355 13.7207 13.3613 13 13.8281ZM15.4844 10C15.4219 11.3828 15.1016 12.5879 14.6094 13.4688C15.8867 12.7852 16.8008 11.5039 16.9688 10H15.4844ZM19 19C19 18.7227 18.7773 18.5 18.5 18.5H6.5C6.22461 18.5 6 18.7227 6 19C6 19.2773 6.22461 19.5 6.5 19.5H18.5C18.7773 19.5 19 19.2773 19 19Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(PassPortIcon);
