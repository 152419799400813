import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const BUSDIcon = ({ ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 336.41 337.42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fill={props.color || '#f0b90b'}
        stroke={props.color || '#f0b90b'}
        d="M168.2.71l41.5,42.5L105.2,147.71l-41.5-41.5Z"
      />
    <path
        fill={props.color || '#f0b90b'}
        stroke={props.color || '#f0b90b'}
        d="M231.2,63.71l41.5,42.5L105.2,273.71l-41.5-41.5Z"
      />
    <path
        fill={props.color || '#f0b90b'}
        stroke={props.color || '#f0b90b'}
        d="M42.2,126.71l41.5,42.5-41.5,41.5L.7,169.21Z"
      />
    <path
        fill={props.color || '#f0b90b'}
        stroke={props.color || '#f0b90b'}
        d="M294.2,126.71l41.5,42.5L168.2,336.71l-41.5-41.5Z"
      />
  </svg>
  );

export default forwardRef(BUSDIcon);
