import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const BadgeIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="24"
      viewBox="0 0 21 24"
      ref={forwardedRef}
      {...props}
    >
    <path
        d="M19.5131 9.46875C19.2525 8.48063 19.0088 7.54687 19.0088 6.24C19.0088 4.6125 20.0888 2.65313 20.0981 2.63437C20.2031 2.44687 20.1712 2.2125 20.0194 2.06063L18.0994 0.140625C17.9194 -0.039375 17.6006 -0.039375 17.4206 0.140625C16.7944 0.766875 15.4013 1.17 13.8694 1.17C12.3769 1.17 11.055 0.77625 10.4194 0.140625C10.2319 -0.046875 9.92813 -0.046875 9.74063 0.140625C9.105 0.77625 7.78312 1.17 6.2925 1.17C4.75875 1.17 3.36563 0.766875 2.73937 0.140625C2.55187 -0.046875 2.24813 -0.046875 2.06063 0.140625L0.140625 2.06063C-0.0112498 2.2125 -0.0431251 2.44687 0.0618749 2.63437C0.0712502 2.65313 1.15125 4.60687 1.15125 6.24C1.15125 7.575 0.905625 8.51062 0.645 9.50062C0.343125 10.6462 0 11.9438 0 14.0156C0 19.8656 5.33813 22.0181 8.20687 23.175C8.91562 23.4619 9.52875 23.7094 9.79313 23.9062C9.87937 23.9681 9.97875 24 10.08 24C10.1813 24 10.2806 23.9681 10.3669 23.9062C10.6313 23.7094 11.2444 23.4619 11.9531 23.175C14.8219 22.0181 20.16 19.8656 20.16 14.0156C20.16 11.9363 19.815 10.6256 19.5131 9.46875Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(BadgeIcon);
