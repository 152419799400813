import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const PoolIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      ref={forwardedRef}
      {...props}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0.5H15C18 0.5 20 2 20 5.5V12.5C20 16 18 17.5 15 17.5H5C2 17.5 0 16 0 12.5V5.5C0 2 2 0.5 5 0.5ZM3.5 12.25C3.91 12.25 4.25 11.91 4.25 11.5V6.5C4.25 6.09 3.91 5.75 3.5 5.75C3.09 5.75 2.75 6.09 2.75 6.5V11.5C2.75 11.91 3.09 12.25 3.5 12.25ZM10 12C8.34 12 7 10.66 7 9C7 7.34 8.34 6 10 6C11.66 6 13 7.34 13 9C13 10.66 11.66 12 10 12ZM16.5 12.25C16.91 12.25 17.25 11.91 17.25 11.5V6.5C17.25 6.09 16.91 5.75 16.5 5.75C16.09 5.75 15.75 6.09 15.75 6.5V11.5C15.75 11.91 16.09 12.25 16.5 12.25Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(PoolIcon);
