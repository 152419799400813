/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { CSS } from '@stitches/react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import {
  BellIcon,
  ExclamationTriangleIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { useHarmonicIntervalFn } from 'react-use';

import { styled } from 'configs/stitches';
import { CheckCircleIcon } from 'components/icons';

import { Event, Position, ToastData, toastManager, ToastType } from './service';
import { reducer } from './store';
import { Box, Flex } from '..';
import { SlideEnd, SlideIn, SlideOut } from './animation';
import { Text } from '../Text';
import Button from '../Button';

export type ToastProviderProps = ToastPrimitive.ToastProviderProps & { position: Position };
export interface ToastProps {
  toast?: ToastData;
  open: boolean;
  onOpenChange: (open) => void;
  onEnd: () => void;
}

const icons: Record<ToastType, JSX.Element> = {
  success: <CheckCircleIcon />,
  error: <XCircleIcon />,
  warning: <ExclamationTriangleIcon />,
  default: <BellIcon />,
};

//#region Toast Container
export const ToastProvider: React.FC<ToastProviderProps> = props => {
  const ref = useRef<HTMLOListElement>(null);
  const { position } = props;
  const [{ toasts = [], queue }, dispatch] = useReducer(reducer, { queue: [], toasts: [] });

  const handlers = useMemo(
    () => ({
      add: () => {
        dispatch({ type: 'add' });
      },
      create: (params: ToastData) => {
        if (params.possition !== position) {
          return;
        }
        dispatch({
          type: Event.SHOW,
          toast: { ...params },
        });
      },
      hide: (id: string) => {
        dispatch({
          type: Event.HIDE,
          id,
        });
      },
      remove: (id: string) => {
        dispatch({
          type: 'remove',
          id,
        });
      },
    }),
    [toasts, queue]
  );

  useEffect(() => {
    toastManager.on(Event.SHOW, handlers.create).on(Event.HIDE, handlers.remove);
  }, []);

  useHarmonicIntervalFn(() => {
    const height = window.screen.height;
    const size = ref.current?.clientHeight || 0;
    if (size < height && queue.length) {
      handlers.add();
    }
  }, 500);

  return (
    <ToastPrimitive.Provider {...props}>
      {[...toasts]?.map(toast => (
        <Toast
          toast={toast}
          key={toast?.id}
          open={toast?.open || false}
          onOpenChange={open => !open && handlers.hide(toast?.id || '')}
          onEnd={() => handlers.remove(toast?.id || '')}
        />
      ))}
      <ToastViewPort possition={position} ref={ref} />
    </ToastPrimitive.Provider>
  );
};

ToastProvider.defaultProps = {
  swipeDirection: 'down',
};
//#endregion

//#region Styled

const StyledTitle = styled(Text, {
  gridArea: 'title',
  fontWeight: 'bold',
  fontSize: '$2',
  paddingTop: '$2',
});
const StyledDescription = styled(ToastPrimitive.Description, {
  width: '100%',
  overflow: 'hidden',
  display: '-webkit-box',
  fontSize: '$sm',
  '-webkit-line-clamp': 7,
  '-webkit-box-orient': 'vertical',
  padding: 0,
  margin: 0,
  paddingBottom: '$2',
});
const StyledClose = styled(ToastPrimitive.Close, {});

const positions: Record<Position, CSS> = {
  'top-left': {
    top: 0,
    left: 0,
  },
  'top-right': {
    top: 0,
    right: 0,
  },
  'bottom-right': {
    bottom: 0,
    right: 0,
  },

  'bottom-left': {
    bottom: 0,
    left: 0,
  },
};
const animations: Record<Position, CSS> = {
  'top-left': {
    $$animationIn: SlideIn['left-right'],
    $$animationOut: SlideOut['left-right'],
    $$animationEnd: SlideEnd['left-right'],
  },
  'top-right': {
    $$animationIn: SlideIn['right-left'],
    $$animationOut: SlideOut['right-left'],
    $$animationEnd: SlideEnd['right-left'],
  },
  'bottom-right': {
    $$animationIn: SlideIn['right-left'],
    $$animationOut: SlideOut['right-left'],
    $$animationEnd: SlideEnd['right-left'],
  },
  'bottom-left': {
    $$animationIn: SlideIn['left-right'],
    $$animationOut: SlideOut['left-right'],
    $$animationEnd: SlideEnd['left-right'],
  },
};
const StyledViewPort = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  width: 390,
  padding: '$2',
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 2147483647,
  variants: {
    possition: { ...positions },
  },
  defaultVariants: {
    possition: 'top-right',
  },
});
const StyleToast = styled(ToastPrimitive.Root, {
  animationFillMode: 'both',
  transformOrigin: 'top',
  overflow: 'hidden',
  cursor: 'pointer',
  boxSizing: 'border-box',

  border: 'none',
  padding: 0,
  maxHeight: 999,
  transition: 'maxHeight linear',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `$$animationIn 500ms linear forwards`,
    },
    '&[data-state="closed"]': {
      animation: `$$animationOut  500ms ease-in forwards`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      // maxHeight: 0,
    },
    '&[data-swipe="end"]': {
      // maxHeight: 0,
    },
  },
  variants: {
    animation: animations,
  },
});
const ToastContent = styled(Box, {
  maxHeight: 300,
  width: 300,
  position: 'relative',
  borderRadius: '$2',
  overflow: 'hidden',
  display: 'flex',
  color: '$gray12',
  flexDirection: 'column',
});

//#endregion

export const ToastTitle = StyledTitle;
export const ToastDescription = StyledDescription;
export const ToastViewPort = StyledViewPort;
export const ToastClose = StyledClose;

const ToastStatus = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  color: '$white',
  padding: '$2 $2',
  fontSize: '$1',
  svg: { width: '1.5em' },

  variants: {
    color: {
      success: {
        backgroundColor: '$green9',
        borderColor: '$green9',
      },
      warning: {
        backgroundColor: '$orange9',
        borderColor: '$orange9',
      },
      error: {
        backgroundColor: '$red9',
        borderColor: '$red9',
      },
      default: {
        backgroundColor: '$gray2',
        borderColor: '$gray2',
      },
    },
  },
});
const ToastBox = styled(Flex, {
  position: 'relative',
  alignItems: 'center',
  width: '100%',
  borderRadius: '$2',
  overflow: 'hidden',
  backgroundColor: '$gray3',
  maxWidth: 400,
  mt: 10,
  gap: 10,
});
const ButtonClose = styled(Button, {
  position: 'absolute',
  padding: '0 !important',
  lineHeight: 1,
  fontSize: 12,
  cursor: 'pointer',
  top: '$1',
  right: '$1',
});

export const Toast: React.FC<ToastProps> = ({ onOpenChange, onEnd, ...props }) => {
  const [toast] = useState(props.toast);
  const { type, content, title, possition, delay } = toast || {};

  const onChange = (open: boolean) => {
    onOpenChange(open);
    !open &&
      setTimeout(() => {
        onEnd();
      }, 500);
  };

  return (
    <StyleToast
      {...props}
      type="foreground"
      onOpenChange={onChange}
      animation={possition}
      duration={delay}
    >
      <ToastBox>
        {type && <ToastStatus color={type}>{icons[type]}</ToastStatus>}
        <ToastContent>
          {title && <ToastTitle>{title}</ToastTitle>}
          {content && <ToastDescription>{content || ''}</ToastDescription>}
        </ToastContent>{' '}
        <ToastClose asChild>
          <ButtonClose variant="link" color="secondary">
            <XMarkIcon />
          </ButtonClose>
        </ToastClose>
      </ToastBox>
    </StyleToast>
  );
};

export default Toast;
