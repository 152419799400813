import { keyframes, styled } from 'configs/stitches';

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const Spinner = styled('div', {
  width: '$md',
  height: '$md',
  borderRadius: '50%',
  border: '4px solid $greenA11',
  borderTopColor: '$green9',
  animation: `${spin} 1s infinite linear`,
  display: 'inline-block',
  variants: {
    size: {
      sm: {
        width: '1rem',
        height: '1rem',
        borderWidth: '3px !important',
      },
      lg: {
        width: '$lg',
        height: '$lg',
      },
    },
    color: {
      gray: {
        borderColor: '$grayA11',
        borderTopColor: '$gray9',
      },
    },
  },
});

Spinner.displayName = 'Spinner';

export default Spinner;
