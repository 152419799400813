import dayjs, { Dayjs, unix } from 'dayjs';
import { BigNumber, BigNumberish, ethers } from 'ethers';
import fromExp from 'from-exponential';

import { ipfs, mainChain } from 'configs';

const { formatUnits, parseUnits } = ethers.utils;

export function truncateAddress(addr: string, max = 9) {
  max = Math.max(max, 8);
  const head = Math.floor((max - 3) / 2);
  const tail = Math.ceil((max - 3) / 2);

  return addr.substring(0, head) + '...' + addr.substring(addr.length - tail);
}

export function blocksToDuration(blocks: number) {
  return dayjs.duration(blocks * 3, 's');
}

const formatterCache: Record<string, Intl.NumberFormat> = {};
export function formatNumber(num: number, opts?: Intl.NumberFormatOptions): string {
  if (isNaN(num)) return '-';
  const ckey = opts ? ethers.utils.hashMessage(JSON.stringify({ ...opts })) : 'default';
  let nf = formatterCache[ckey];
  if (!nf) {
    nf = new Intl.NumberFormat('en-US', opts);
    formatterCache[ckey] = nf;
  }

  return nf.format(num);
}

export function makeError(error) {
  if (!error) return;

  if (error instanceof Error) return error;

  let msg = `${error}`;
  if (typeof error === 'object') {
    msg = error.data?.message || error.message || msg;
  }
  return new Error(msg);
}

export function getTokenPrice(token: Token, amount: BigNumber, price: BigNumber) {
  return price.mul(amount).div(BigNumber.from(10).pow(token.decimals));
}

export function urlFromCID(img: string) {
  if (typeof img !== 'string') {
    return img;
  }
  if (!img) return img;
  if (img.match(/^(https?:\/\/|\/)/i)) {
    return img;
  }

  return `${ipfs.gateways[0]}${img}`;
}

export function newDateRange(from, to) {
  if (!from && !to) return;
  return {
    from: from ? dayjs(from) : undefined,
    to: to ? dayjs(to) : undefined,
  } as DateRange;
}

export function numberToBigNumber(n: number, decimals = 18) {
  if (n >= Number.MAX_SAFE_INTEGER) return BigNumber.from(0);

  return parseUnits(fromExp(Math.floor(n * 1e16) / 1e16), decimals);
}

export function bigNumberToNumber(b: BigNumberish, decimals = 18) {
  return parseFloat(formatUnits(b, decimals));
}

export function isApoint(token: Token) {
  return token.address.toLowerCase() === mainChain.tokens.APOINT.address.toLowerCase();
}

export function slugify(string) {
  const a = 'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b = 'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  // eslint-disable-next-line
  return string
    .toString()
    .toLowerCase()
    .replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a')
    .replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e')
    .replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i')
    .replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o')
    .replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u')
    .replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y')
    .replace(/đ/gi, 'd')
    .replace(/\s+/g, '-')
    .replace(p, c => b.charAt(a.indexOf(c)))
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export const omitEmpty = (params: Record<string, unknown>) =>
  Object.keys(params)
    .filter(k => params[k] !== undefined)
    .reduce((c, v) => ({ ...c, [v]: params[v] }), {});

export function formatTimestampWithUTC(
  ts: string | number | Dayjs,
  format = 'MMM D, H:mm',
  utcFormat = 'H:mm'
) {
  const d = typeof ts === 'number' ? unix(ts) : dayjs(ts);

  if (d.isUTC()) {
    return d.format(format);
  }

  return `${d.format(format)} (${d.utc().format(utcFormat || format)} UTC)`;
}

export function getTokenFromAddress(address?: string) {
  return Object.values(mainChain.tokens).find(
    r => r.address.toLocaleLowerCase() == address?.toLocaleLowerCase()
  );
}
