import React, { ForwardedRef, forwardRef, useRef } from 'react';

import { styled } from 'configs/stitches';

import Label from './Label';

import { Box, Input } from '.';

type ValueImage<IsMulti extends boolean> = IsMulti extends true ? string[] : string;

interface Props<IsMulti extends boolean> {
  className?: string;
  value?: ValueImage<IsMulti>;
  title?: string;
  onChange?: (image: ValueImage<IsMulti> | any) => void;
  disabled?: boolean;
  reset?: boolean;
  multiple?: IsMulti;
}
let fileId = 1;
const ImageInput = forwardRef(
  <T extends boolean>(
    { className, value, disabled, reset, multiple, onChange, ...props }: Props<T>,
    fileRef: ForwardedRef<HTMLInputElement>
  ) => {
    const ref = useRef<HTMLDivElement>(null);
    const id = `iamge-input-${fileId++}`;

    const onDragEnter = () => ref.current && ref.current.classList.add('dragover');

    const onDragLeave = () => ref.current && ref.current.classList.remove('dragover');

    const onDrop = () => ref.current && ref.current.classList.remove('dragover');

    const onChangeImage = async event => {
      const { files } = event.target;

      if (files.length === 0) {
        return;
      }
      const filesArray = Array.prototype.slice.call(files);
      const images = await Promise.all(filesArray.map(fileToDataURL));

      ref.current && !reset ? (ref.current.style.backgroundImage = `url(${images[0]})`) : null;

      const result = multiple ? images : images[0];

      onChange && onChange(result as any);
    };

    const bgi = multiple ? value?.[0] : value;
    return (
      <Box
        className={className}
        css={{ bgi }}
        ref={ref}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <Label htmlFor={id} className="btn" hidden={disabled}>
          <Box>{props.title || 'Choose File'}</Box>
        </Label>
        <Input {...props} onChange={e => e} value={value ? 1 : undefined} ref={fileRef} />
        <Input
          id={id}
          type="file"
          onChange={onChangeImage}
          accept=".jpg, .jpeg, .png"
          disabled={disabled}
          multiple={multiple}
        />
      </Box>
    );
  }
);

ImageInput.displayName = 'ImageInput';
export default styled(ImageInput, {
  padding: '$2',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: '$2',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  alignItems: 'center',
  backgroundColor: '$gray4',
  position: 'relative',
  '&:hover': {
    '.btn': {
      display: 'unset',
    },
  },
  '.btn': {
    px: '$1',
    py: '$1',
    display: 'inline-block',
    border: 'none',
    backgroundColor: '$gray3',
    opacity: 0.5,
    fontSize: '$sm',
    height: 'fit-content',
    cursor: 'pointer',
    textAlign: 'center',
  },
  '&.dragover': {
    backgroundColor: '$gray3',
  },
  input: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
});

function fileToDataURL(file) {
  const reader = new FileReader();
  return new Promise(function (resolve) {
    reader.onload = function (event: any) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
}
