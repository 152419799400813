import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const ShoppingIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.42032 0.00217114C0.7152 -0.0262174 0.219223 0.229211 0 0.928023V1.36832C0.241371 2.05294 0.73872 2.29794 1.44219 2.26112C1.86617 2.23894 2.29162 2.24448 2.71701 2.25002C2.8952 2.25234 3.07339 2.25466 3.25145 2.25495C3.59801 2.25543 3.66727 2.32448 3.7176 2.67522C3.91646 4.06167 4.11792 5.44777 4.31993 6.83374C4.43216 7.60379 4.54557 8.37367 4.65899 9.14355C4.904 10.8067 5.14901 12.4698 5.38197 14.1345C5.62265 15.8542 6.87593 16.9816 8.60757 16.9826C12.4144 16.9848 16.2213 16.9851 20.0281 16.9824C21.6883 16.9813 22.8987 16.0052 23.2258 14.3913C23.821 11.4557 24.4072 8.51813 24.9907 5.58011C25.0296 5.384 25.0528 5.17047 25.0181 4.97675C24.9125 4.38759 24.4486 4.06379 23.7581 4.06373C19.5506 4.06352 15.3431 4.06355 11.1356 4.06357C9.73477 4.06358 8.33391 4.06359 6.93305 4.06359C6.87647 4.06359 6.81985 4.06285 6.76323 4.06211C6.63223 4.06041 6.50123 4.0587 6.37077 4.06613C6.22498 4.07435 6.18027 4.0195 6.15867 3.87632C6.1383 3.7415 6.11851 3.60652 6.09872 3.4715C6.0181 2.92164 5.93741 2.3713 5.81712 1.83001C5.61072 0.901554 4.66286 0.100297 3.71664 0.0478397C3.29971 0.0246748 2.88172 0.0223422 2.46368 0.0200093C2.11573 0.0180675 1.76775 0.0161256 1.42032 0.00217114ZM15.75 7.00002C15.3358 7.00002 15 7.3358 15 7.75002C15 8.16423 15.3358 8.50002 15.75 8.50002H21.25C21.6642 8.50002 22 8.16423 22 7.75002C22 7.3358 21.6642 7.00002 21.25 7.00002H15.75Z"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9822 18.0422C20.5935 18.0513 21.8808 19.347 21.8772 20.956C21.8737 22.5597 20.5648 23.868 18.9683 23.8637C17.3589 23.8595 16.0419 22.5334 16.0543 20.9296C16.0666 19.3259 17.3776 18.0331 18.9822 18.0422"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8372 20.9523C11.8327 22.5282 10.5348 23.8619 9.00339 23.8645C7.43489 23.867 6.11756 22.5343 6.12086 20.9481C6.12408 19.3691 7.41926 18.043 8.95594 18.0452C10.5411 18.0476 11.8417 19.3598 11.8372 20.9523"
        fill={color}
      />
  </svg>
  );

export default forwardRef(ShoppingIcon);
