import { Event, ToastData } from './service';

export type ActionType = Event.SHOW | Event.HIDE | 'remove' | 'add';

type Action =
  | {
      type: Event.SHOW;
      toast: ToastData;
    }
  | {
      type: Event.HIDE | 'remove';
      id: string;
    }
  | {
      type: 'add';
    }
  | {
      type: 'change-open';
      open: boolean;
    };

interface State {
  toasts?: ToastData[];
  queue: ToastData[];
  cache?: ToastData;
}

export const reducer = (state: State, action: Action) => {
  const { toasts = [], queue } = { ...state };
  switch (action.type) {
    case Event.SHOW: {
      // push
      if (queue.find(t => t.id === action.toast.id)) {
        return state;
      }

      return { toasts, queue: [...queue, action.toast] };
    }
    case Event.HIDE: {
      const toastIndex = toasts.findIndex(t => t.id === action.id);
      if (toastIndex == -1) return state;
      toasts[toastIndex].open = false;
      return { ...state, toasts };
    }
    case 'remove': {
      return { ...state, toasts: toasts.filter(i => i.id !== action.id) };
    }
    case 'add': {
      try {
        const newQueue = [...queue];
        const newToast = newQueue.shift();
        if (!newToast) return state;
        newToast.open = true;
        return { ...state, toasts: [...toasts, newToast], queue: newQueue };
      } catch (e) {
        return state;
      }
    }
    default: {
      return state;
    }
  }
};
