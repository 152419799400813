import { styled } from 'configs/stitches';

export const Table = styled('table', {
  variants: {
    full: {
      true: {
        width: '100%',
      },
    },
    border: {
      true: {
        borderCollapse: 'collapse',
        border: '1px solid $gray5',
        'td,th': { border: '1px solid $gray5', padding: '$1' },
        '.index,.kyc,.wallet': {
          textAlign: 'center',
        },
      },
    },
  },
});
export const TBody = styled('tbody', {});
