import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const TradeIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.609 0H6.391C2.387 0 0 2.387 0 6.391V15.598C0 19.613 2.387 22 6.391 22H15.598C19.602 22 21.989 19.613 21.989 15.609V6.391C22 2.387 19.613 0 15.609 0ZM17.259 13.255C17.215 13.354 17.16 13.442 17.083 13.519L13.838 16.764C13.673 16.929 13.464 17.006 13.255 17.006C13.046 17.006 12.837 16.929 12.672 16.764C12.353 16.445 12.353 15.917 12.672 15.598L14.509 13.761H5.5C5.049 13.761 4.675 13.387 4.675 12.936C4.675 12.485 5.049 12.111 5.5 12.111H16.5C16.61 12.111 16.709 12.133 16.819 12.177C17.017 12.265 17.182 12.419 17.27 12.628C17.347 12.826 17.347 13.046 17.259 13.255ZM5.5 9.889H16.5C16.951 9.889 17.325 9.515 17.325 9.064C17.325 8.613 16.951 8.239 16.5 8.239H7.491L9.328 6.402C9.647 6.083 9.647 5.555 9.328 5.236C9.009 4.917 8.481 4.917 8.162 5.236L4.917 8.481C4.84 8.558 4.785 8.646 4.73 8.745C4.642 8.943 4.642 9.174 4.73 9.372C4.818 9.581 4.983 9.735 5.181 9.823C5.291 9.867 5.39 9.889 5.5 9.889Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(TradeIcon);
