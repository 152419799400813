import React from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { styled } from 'configs/stitches';

import { Flex, Box, Button, Text } from '.';

interface Props {
  className?: string;
  pages: number;
  index: number;
  maxItems?: 3 | 5 | 7;
  onPageChange?: (page: number) => void;
}
const Pagination: React.FC<Props> = ({ className, ...props }) => {
  const { index = 1, pages, onPageChange, maxItems = 7 } = props;
  const IconDots = (
    <Flex className="dot3" align="end" justify="center">
      ...
    </Flex>
  );
  const ItemPage = ({ item }: { item: number }) => (
    <Button
      className={classNames('item', { active: item === index })}
      onClick={() => onPageChange && onPageChange(item)}
    >
      {item}
    </Button>
  );
  const items = Array(maxItems * 2)
    .fill(0)
    .map((_, key) => index + key - maxItems)
    .filter(i => i > 0 && i <= pages) // chặn 2 đầu
    .filter(i => (index <= maxItems / 2 + 1 ? i <= maxItems : true)) // lọc với index <= (maxItems/2 + 1)  => lấy từ 1 - 7
    .filter(i => (index >= pages - maxItems / 2 ? i > pages - maxItems : true)) // lọc với index >= page - 3 => lấy từ page - maxItems, page
    .filter(i =>
      index > maxItems / 2 + 1 && index < pages - maxItems / 2
        ? i >= index - maxItems / 2 && i <= index + maxItems / 2
        : true
    ) // lọc với các trường gợp còn lại
    .map((item, i) =>
      (i === 0 && index > maxItems / 2 + 1 && pages > maxItems) ||
      (i === Math.min(maxItems - 1, pages - 1) && item < pages) ? (
        IconDots
      ) : (
        <ItemPage item={item} key={item} />
      )
    ); // lọc nếu i = 0 và index > (maxItems/2 + 1) và pages > maxItems => biến về icon
  return (
    <Box className={className}>
      <Flex className="pagination">
        <Button
          className="item icon"
          onClick={() => onPageChange && onPageChange(1)}
          disabled={index <= 1}
        >
          <ChevronDoubleLeftIcon />
        </Button>
        <Button
          className="item icon"
          onClick={() => onPageChange && onPageChange(index - 1)}
          disabled={index <= 1}
        >
          <ChevronLeftIcon />
        </Button>
        {items.map((item, i) => (
          <Text key={i}>{item}</Text>
        ))}

        <Button
          className="item icon"
          onClick={() => onPageChange && onPageChange(index + 1)}
          disabled={index >= pages}
        >
          <ChevronRightIcon />
        </Button>
        <Button
          className="item icon"
          onClick={() => onPageChange && onPageChange(pages)}
          disabled={index >= pages}
        >
          <ChevronDoubleRightIcon />
        </Button>
      </Flex>
    </Box>
  );
};

export default styled(Pagination, {
  display: 'inline-block',
  padding: '$1',
  borderRadius: '$2',
  '.item': {
    width: 30,
    height: 30,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '$gray3',
    border: '1px solid $gray5',
    mx: 3,
    fontSize: 14,
    padding: 0,
    '&:focus, &:hover': {
      backgroundColor: '$gray5',
      boxShadow: 'none',
    },
    '&.icon': {
      fontSize: 10,
    },
    '&.active': {
      backgroundColor: '$gray9',
    },
    '&:disabled': {
      backgroundColor: '$gray2 !important',
      boxShadow: 'none',
      opacity: 0.5,
      color: '$gray9',
    },
  },
  '.dot3': {
    width: 30,
    height: '100%',
    border: '1px solid $gray5',
    letterSpacing: 3,
    mx: 3,
    paddingLeft: 3,
    alignItems: 'center',

    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '$gray3',
    fontSize: 14,
    borderRadius: '$2',
    userSelect: 'none',
  },
});
