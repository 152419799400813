import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const APOINTIcon = ({ ...props }: IconProps, forwardedRef) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} ref={forwardedRef} viewBox="0 0 512 512">
    <g id="Icon-1-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="APOINT">
        <g id="Group">
          <g id="BG">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1" />
            </mask>
            <g id="Clip-2" />
            <path
                d="M337.122509,-0.0004096 L174.875853,-0.0004096 C158.923981,-0.0004096 143.623373,6.3381504 132.342989,17.6185344 L17.6181248,132.343398 C6.3377408,143.623782 -0.0008192,158.92439 -0.0008192,174.876262 L-0.0008192,337.122918 C-0.0008192,353.07479 6.3377408,368.375398 17.6181248,379.65783 L132.342989,494.380646 C143.623373,505.663078 158.923981,511.99959 174.875853,511.99959 L337.122509,511.99959 C353.076429,511.99959 368.377037,505.663078 379.657421,494.380646 L494.382285,379.65783 C505.662669,368.375398 511.999181,353.07479 511.999181,337.122918 L511.999181,174.876262 C511.999181,158.92439 505.662669,143.623782 494.382285,132.343398 L379.657421,17.6185344 C368.377037,6.3381504 353.076429,-0.0004096 337.122509,-0.0004096"
                id="Fill-1"
                fill="#45B012"
                mask="url(#mask-2)"
              />
          </g>
          <path
              d="M326.679552,32.9084928 L185.320448,32.9084928 C172.024832,32.9084928 159.276032,38.1902848 149.875712,47.5906048 L49.918976,147.547341 C40.518656,156.947661 35.236864,169.698509 35.236864,182.992077 L35.236864,324.351181 C35.236864,337.644749 40.518656,350.395597 49.918976,359.795917 L149.875712,459.750605 C159.276032,469.152973 172.024832,474.432717 185.320448,474.432717 L326.679552,474.432717 C339.975168,474.432717 352.723968,469.152973 362.124288,459.750605 L462.081024,359.795917 C471.481344,350.395597 476.763136,337.644749 476.763136,324.351181 L476.763136,182.992077 C476.763136,169.698509 471.481344,156.947661 462.081024,147.547341 L362.124288,47.5906048 C352.723968,38.1902848 339.975168,32.9084928 326.679552,32.9084928"
              id="Fill-4"
              fill="#246E00"
            />
          <path
              d="M322.331648,51.3144832 L189.668352,51.3144832 C179.033088,51.3144832 168.832,55.5395072 161.311744,63.0597632 L67.505152,156.866355 C59.984896,164.386611 55.759872,174.587699 55.759872,185.222963 L55.759872,317.888307 C55.759872,328.521523 59.984896,338.720563 67.505152,346.240819 L161.311744,440.049459 C168.832,447.569715 179.033088,451.794739 189.668352,451.794739 L322.331648,451.794739 C332.966912,451.794739 343.168,447.569715 350.688256,440.049459 L444.494848,346.240819 C452.015104,338.720563 456.240128,328.521523 456.240128,317.888307 L456.240128,185.222963 C456.240128,174.587699 452.015104,164.386611 444.494848,156.866355 L350.688256,63.0597632 C343.168,55.5395072 332.966912,51.3144832 322.331648,51.3144832"
              id="Fill-6"
              fill="#308F03"
            />
          <path
              d="M189.668557,54.3854592 C179.778765,54.3854592 170.478797,58.2377472 163.484877,65.2316672 L69.6762368,159.040307 C62.6843648,166.034227 58.8320768,175.332147 58.8320768,185.223987 L58.8320768,317.887283 C58.8320768,327.777075 62.6843648,337.077043 69.6762368,344.070963 L163.484877,437.877555 C170.476749,444.871475 179.776717,448.723763 189.668557,448.723763 L322.331853,448.723763 C332.223693,448.723763 341.523661,444.871475 348.517581,437.877555 L442.324173,344.070963 C449.316045,337.077043 453.168333,327.777075 453.168333,317.887283 L453.168333,185.223987 C453.168333,175.332147 449.316045,166.034227 442.324173,159.040307 L348.517581,65.2316672 C341.521613,58.2377472 332.221645,54.3854592 322.331853,54.3854592 L189.668557,54.3854592 Z M322.331853,454.867763 L189.668557,454.867763 C178.134221,454.867763 167.292109,450.374451 159.141069,442.221363 L65.3324288,348.414771 C57.1793408,340.259635 52.6880768,329.417523 52.6880768,317.887283 L52.6880768,185.223987 C52.6880768,173.691699 57.1793408,162.849587 65.3324288,154.694451 L159.141069,60.8878592 C167.294157,52.7327232 178.136269,48.2414592 189.668557,48.2414592 L322.331853,48.2414592 C333.864141,48.2414592 344.706253,52.7327232 352.861389,60.8878592 L446.667981,154.694451 C454.823117,162.849587 459.312333,173.691699 459.312333,185.223987 L459.312333,317.887283 C459.312333,329.417523 454.823117,340.259635 446.667981,348.414771 L352.861389,442.221363 C344.708301,450.374451 333.866189,454.867763 322.331853,454.867763 L322.331853,454.867763 Z"
              id="Fill-8"
              fill="#231F20"
              opacity="0.5"
            />
        </g>
        <polygon
            id="Fill-10"
            fill="#FFFFFF"
            points="315.535565 117.903565 310.354125 108.939469 301.222093 104.591565 319.779021 97.7860608"
          />
        <path
            d="M214.13847,227.632538 C213.333606,229.430682 211.92663,230.329754 209.88887,230.329754 L195.198566,230.329754 C194.238054,230.329754 193.496678,230.061466 192.974438,229.551514 C192.45015,229.010842 192.200294,228.382106 192.222822,227.632538 C192.24535,226.88297 192.382566,226.282906 192.605798,225.863066 L219.215462,174.386586 L246.816358,120.962458 C247.293542,120.001946 247.795302,119.34249 248.405606,118.982042 C249.01591,118.592922 249.892454,118.412698 251.059814,118.412698 L267.51959,118.412698 C269.559398,118.412698 270.913126,119.252378 271.609446,120.962458 L277.730918,134.483354 L303.60535,116.82345 L221.034086,214.920602 C220.063334,216.270234 219.090534,217.679258 218.322534,219.237786 L214.13847,227.632538 Z"
            id="Fill-12"
            fill="#FFFFFF"
          />
        <path
            d="M293.935514,219.207475 C290.152858,210.573107 281.655706,205.086515 272.122266,205.086515 L245.858714,205.086515 L283.601306,147.435315 L319.234458,226.193203 C319.431066,226.613043 319.535514,227.151667 319.515034,227.782451 C319.463834,229.490483 318.357914,230.330163 316.230042,230.330163 L301.689242,230.330163 C300.609946,230.330163 299.80713,230.121267 299.221402,229.701427 C298.664346,229.281587 298.115482,228.591411 297.60553,227.632947 L293.935514,219.207475 Z"
            id="Fill-14"
            fill="#FFFFFF"
          />
        <path
            d="M137.248563,292.68951 C139.601715,292.68951 140.779315,291.513958 140.779315,289.158758 L140.779315,276.22359 C140.779315,273.872486 139.601715,272.696934 137.248563,272.696934 L129.017651,272.696934 L129.017651,292.68951 L137.248563,292.68951 Z M129.017651,312.67799 L129.017651,331.492966 C129.017651,333.846118 127.842099,335.02167 125.488947,335.02167 L110.202675,335.02167 C107.849523,335.02167 106.673971,333.846118 106.673971,331.492966 L106.673971,256.231014 C106.673971,253.881958 107.849523,252.706406 110.202675,252.706406 L147.832627,252.706406 C158.416691,252.706406 163.118899,257.408614 163.118899,267.99063 L163.118899,297.391718 C163.118899,307.975782 158.416691,312.67799 147.832627,312.67799 L129.017651,312.67799 Z"
            id="Fill-16"
            fill="#FFFFFF"
          />
        <path
            d="M210.149581,275.047629 C210.149581,272.698573 208.974029,271.520973 206.620877,271.520973 L199.565517,271.520973 C197.212365,271.520973 196.036813,272.698573 196.036813,275.047629 L196.036813,312.677581 C196.036813,315.028685 197.212365,316.208333 199.565517,316.208333 L206.620877,316.208333 C208.974029,316.208333 210.149581,315.028685 210.149581,312.677581 L210.149581,275.047629 Z M173.691085,266.814669 C173.691085,256.230605 178.397389,251.530445 188.981453,251.530445 L217.204941,251.530445 C227.789005,251.530445 232.491213,256.230605 232.491213,266.814669 L232.491213,320.910541 C232.491213,331.494605 227.789005,336.196813 217.204941,336.196813 L188.981453,336.196813 C178.397389,336.196813 173.691085,331.494605 173.691085,320.910541 L173.691085,266.814669 Z"
            id="Fill-18"
            fill="#FFFFFF"
          />
        <path
            d="M267.756544,331.493581 C267.756544,333.844685 266.580992,335.022285 264.22784,335.022285 L248.941568,335.022285 C246.588416,335.022285 245.412864,333.844685 245.412864,331.493581 L245.412864,256.231629 C245.412864,253.882573 246.588416,252.707021 248.941568,252.707021 L264.22784,252.707021 C266.580992,252.707021 267.756544,253.882573 267.756544,256.231629 L267.756544,331.493581 Z"
            id="Fill-20"
            fill="#FFFFFF"
          />
        <path
            d="M319.492506,256.231629 C319.492506,253.882573 320.670106,252.707021 323.023258,252.707021 L338.30953,252.707021 C340.660634,252.707021 341.838234,253.882573 341.838234,256.231629 L341.838234,331.493581 C341.838234,333.844685 340.660634,335.022285 338.30953,335.022285 L318.316954,335.022285 C315.967898,335.022285 314.907034,333.844685 314.200474,331.493581 L305.383834,300.921037 L304.206234,300.921037 L304.206234,331.493581 C304.206234,333.844685 303.030682,335.022285 300.67753,335.022285 L285.391258,335.022285 C283.038106,335.022285 281.862554,333.844685 281.862554,331.493581 L281.862554,256.231629 C281.862554,253.882573 283.038106,252.707021 285.391258,252.707021 L305.383834,252.707021 C307.736986,252.707021 308.793754,253.882573 309.498266,256.231629 L318.316954,286.808269 L319.492506,286.808269 L319.492506,256.231629 Z"
            id="Fill-22"
            fill="#FFFFFF"
          />
        <path
            d="M353.58249,272.697549 C351.233434,272.697549 350.057882,271.521997 350.057882,269.168845 L350.057882,256.231629 C350.057882,253.882573 351.233434,252.707021 353.58249,252.707021 L401.796506,252.707021 C404.149658,252.707021 405.327258,253.882573 405.327258,256.231629 L405.327258,269.168845 C405.327258,271.521997 404.149658,272.697549 401.796506,272.697549 L388.865434,272.697549 L388.865434,331.493581 C388.865434,333.844685 387.687834,335.022285 385.334682,335.022285 L370.050458,335.022285 C367.697306,335.022285 366.519706,333.844685 366.519706,331.493581 L366.519706,272.697549 L353.58249,272.697549 Z"
            id="Fill-24"
            fill="#FFFFFF"
          />
      </g>
    </g>
  </svg>
  );

export default forwardRef(APOINTIcon);
