import React, { HTMLAttributes, useEffect, useRef } from 'react';

import { styled } from 'configs/stitches';

import { Box } from '.';

const HtmlInput: React.FC<
  HTMLAttributes<HTMLDivElement> & {
    value?: string;
    onChange?: (value) => void;
    disabled?: boolean;
  }
> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const { onChange, disabled } = props;
  useEffect(() => {
    const target = document.createTextNode('');
    const el = ref.current;
    if (!el) return;

    el.appendChild(target);
    // do not move caret if element was not focused
    const isTargetFocused = document.activeElement === el;
    if (target !== null && target.nodeValue !== null && isTargetFocused) {
      const sel = window.getSelection();
      if (sel !== null) {
        const range = document.createRange();
        range.setStart(target, target.nodeValue.length);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      }
      if (el instanceof HTMLElement) el.focus();
    }
  });
  return (
    <Box
      {...props}
      contentEditable={!disabled}
      dangerouslySetInnerHTML={{ __html: props.value || '' }}
      onInput={e => onChange && onChange(e.currentTarget.innerHTML)}
      ref={ref}
    />
  );
};

export default styled(HtmlInput, {
  backgroundColor: '$gray1',
  borderRadius: '$2',
  padding: '$2',
  fontSize: '1rem',
  border: 'none',
  minHeight: 100,
  overflow: 'auto',
  '&:focus': { boxShadow: `0 0 0 2px $colors$gray5`, border: 'none', outline: 'none' },
  '&:active': {},
  '&:empty:before': {
    content: 'attr(placeHolder)',
    color: '$gray10',
  },
  variants: {
    size: {
      sm: {
        fontSize: 'sm',
        padding: '$1',
      },
      lg: {
        fontSize: 'lg',
        padding: '$3',
      },
    },
  },
});
