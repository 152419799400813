import { createStitches } from '@stitches/react';
import { redDark, redDarkA, orangeDark, blueDark } from '@radix-ui/colors';

export const { styled, css, globalCss, keyframes, theme, createTheme, getCssText, config } =
  createStitches({
    theme: {
      colors: {
        white: '#fff',
        white9: '#fff',
        white8: '$gray11',

        gray1: '#141416',
        gray2: '#1C1D23',
        gray3: '#23262F',
        gray4: '#2C303A',
        gray5: '#353945',
        gray6: '#464B58',
        gray7: '#565C6B',
        gray8: '#676D7E',
        gray9: '#777E90',
        gray10: '#8C92A1',
        gray11: '#A8ADB8',
        gray12: '#F5F6F7',

        grayA1: '#141416dd',
        grayA2: 'rgba(35,38,47,0.8)',
        grayA5: 'rgba(53,57,69,0.5)',
        grayA5_3: 'rgba(53,57,69,0.3)',
        grayA6: '#464B5899',
        grayA8: '#676D7Eaa',
        grayA11: '#A8ADB833',

        green1: '#0E1A0A',
        green2: '#15260F',
        green3: '#264021',
        green4: '#275718',
        green5: '#2B601A',
        green6: '#2F6A1D',
        green7: '#347E1D',
        green8: '#39911d',
        green9: '#61B447',
        green10: '#71BE59',
        green11: '#80c86a',
        green12: '#F4FAF2',

        greenA2: '#15260FBB',
        greenA3: '#264021aa',
        greenA5: '#2B601A99',
        greenA7: '#347E1D88',
        greenA11: '#80c86a33',

        greenA9_1: 'rgba(97, 180, 71, 0.1)',

        yellow1: '#F8E71C',

        ...redDark,
        ...orangeDark,
        ...blueDark,
        ...redDarkA,

        orange9: '#FF9500',
        orangeA9_2: 'rgba(255, 149, 0, 0.2)',

        red9: '#FF3B30',
        redA9_2: 'rgba(255, 59, 48, 0.2)',
      },
      shadows: {
        boxHover: '0 0 0 1px rgba(0,0,0,0.6),0 2px 4px rgba(0,0,0,0.6),0 8px 24px rgba(0,0,0,0.6)',
      },
      fontSizes: {
        xs: '0.75rem',
        sm: '0.875rem',
        '1': '1rem',
        '2': '1.25rem',
        '3': '1.5rem',
        '4': '1.75rem',
        '5': '2rem',
        '6': '2.5rem',
      },
      space: {
        '1': '6px',
        '2': '12px',
        '3': '24px',
        '4': '32px',
        '5': '48px',
        '6': '64px',
      },
      sizes: {
        sidebarWidth: '280px',
        sm: '20px',
        md: '28px',
        lg: '36px',
      },
      radii: {
        '1': '4px',
        '2': '6px',
        '3': '8px',
        '4': '10px',
        '5': '12px',
        '10': '100rem',
      },
      zIndices: {},
    },
    // related: https://github.com/modulz/stitches/issues/885
    media: {
      sm: '(min-width: 576px)',
      md: '(min-width: 768px)',
      mdr: '(768px <= width < 991px)',
      lg: '(min-width: 992px)',
      xl: '(min-width: 1200px)',
      xxl: '(min-width: 1400px)',
    },
    utils: {
      mr: value => ({ marginRight: value }),
      mt: value => ({ marginTop: value }),
      mb: value => ({ marginBottom: value }),
      ml: value => ({ marginLeft: value }),
      mx: value => ({ marginLeft: value, marginRight: value }),
      my: value => ({ marginTop: value, marginBottom: value }),
      px: value => ({ paddingLeft: value, paddingRight: value }),
      py: value => ({ paddingTop: value, paddingBottom: value }),
      linearGradient: value => ({
        backgroundImage: `linear-gradient(${value})`,
      }),
      bgi: value => ({
        backgroundImage: `url('${value}')`,
      }),
    },
  });

export const htmlContent = css({
  'h1,h2,h3,h4,h5,h6': {
    marginBottom: '$2',
  },
  p: {
    marginBottom: '$2',
  },
  img: {
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
  },
  iframe: {
    maxWidth: '100%',
    display: 'block',
    margin: '0 auto',
    aspectRatio: '16/9',
  },
});
