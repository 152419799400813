import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { styled } from 'configs/stitches';

import { Box, Input } from '.';

type Props = React.ComponentProps<typeof Input> & {
  className?: string;
  prepend?: React.ReactNode;
  append?: React.ReactNode;
};
const InputGroup = forwardRef<HTMLInputElement, Props>(
  ({ className, prepend, append, ...props }, ref) => (
    <Box className={classNames(className, 'input-group')}>
      <span className="prepend"> {prepend}</span>
      <Input ref={ref} {...props} />
      <span className="append"> {append}</span>
    </Box>
  )
);
InputGroup.displayName = 'InputGroup';

export default styled(InputGroup, {
  $$bgColor: '$colors$gray1',
  display: 'flex',
  backgroundColor: '$$bgColor',
  padding: '$1',
  height: '100%',
  borderRadius: '$2',
  fontSize: '14',
  alignItems: 'center',
  boxSizing: 'border-box',
  '.prepend, .append': {
    minWidth: 'fit-content',
  },

  '> input': {
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '$2',
    px: '$2',
    boxSizing: 'border-box',
    height: '100%',
    border: 'none',
    boxShadow: `0 0 0 1px $gray6`,
    backgroundColor: 'transparent',
    color: '$gray12',
    '&:focus': {
      boxShadow: `none`,
      border: 'none',
      outline: 'none',
    },
  },
  variants: {
    disabled: {
      true: { pointerEvents: 'none', opacity: 0.8, backgroundColor: '$gray2' },
    },
  },

  '&:focus-within': {
    boxShadow: `0 0 0 2px $colors$gray6`,
    border: 'none',
    outline: 'none',
  },
});
