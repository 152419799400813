import React, { cloneElement, ComponentProps, ReactElement, ReactNode, useMemo } from 'react';
import {
  useForm,
  FormProvider,
  RegisterOptions,
  Controller,
  useFormContext,
  useFieldArray,
  SubmitHandler,
  UseFormReturn,
  UseFormProps,
  Resolver,
  Validate,
  FieldValues,
} from 'react-hook-form';
import { CSSProperties, VariantProps } from '@stitches/react';

import { styled } from 'configs/stitches';

import { Label, Text, Box } from '.';

const FormRoot = styled('form');
type FormRootProps = React.ComponentProps<typeof FormRoot>;
type FormProps<T extends FieldValues> = Pick<FormRootProps, 'css' | 'className' | 'style'> & {
  onSubmit?: SubmitHandler<T>;
  defaultValues?: UseFormProps<T>['defaultValues'];
  controlled?: boolean;
  resolver?: Resolver<T>;
};

interface FormItemChildrenProps<T = any> {
  value?: T;
  onChange?: (value: T) => string;
  defaultValue?: T;
}

interface FormChilrenProps<T> {
  label?: string;
  className?: string;
  children: ReactElement<FormItemChildrenProps<T>>;
}

const StyledForm = <T extends FieldValues>({
  onSubmit,
  defaultValues,
  className,
  controlled,
  resolver,
  ...props
}: React.PropsWithChildren<FormProps<T>>) => {
  const methods = useForm({
    defaultValues: defaultValues,
    resolver,
  });

  // For backward compatibility
  if (!controlled) {
    return <FormRoot className={className} onSubmit={onSubmit as any} {...props} />;
  }

  return (
    <FormProvider {...methods}>
      <FormRoot
        onSubmit={onSubmit && methods.handleSubmit(onSubmit)}
        className={className}
        {...props}
      />
    </FormProvider>
  );
};

interface ItemProps<IsNumber extends boolean, T = any> extends FormChilrenProps<T> {
  name: string;
  rules?: Omit<RegisterOptions, 'validate'> & {
    validate?: (methods: UseFormReturn) => Validate<T> | Record<string, Validate<T>>;
  };
  defaultValue?: T;
  onChange?: (value: any, methods: UseFormReturn) => void;
  showError?: boolean;
  setValueAs?: (value: any) => any;
  value?: T;
  required?: boolean | string;
  valueAsNumber?: IsNumber;
}

const Item = <IsNumber extends boolean, T>({
  label,
  name,
  rules,
  children,
  onChange,
  showError = true,
  setValueAs,
  required,
  valueAsNumber,
  ...props
}: ItemProps<IsNumber, T> & ComponentProps<typeof FormGroup>) => {
  const methods = useFormContext();

  return (
    <FormGroup
      label={label}
      required={!!rules?.required || !!required}
      {...props}
      css={{ marginBottom: '$1' }}
    >
      <Controller
        name={name as any}
        rules={{
          ...rules,
          required: required || rules?.required,
          validate: rules?.validate && rules?.validate(methods),
        }}
        render={({ field, fieldState: { error } }) => {
          const props = { ...field } as any;
          props.onChange = v => {
            try {
              const value = v.target.value;
              if (valueAsNumber) {
                if (!value || !Number.isNaN(value)) field.onChange(value ? Number(value) : null);
              } else {
                field.onChange(v);
              }
            } catch {
              field.onChange(v);
            }
          };
          if (onChange) {
            props.onChange = v => {
              onChange(v, methods);
            };
          }
          if (setValueAs) {
            props.value = setValueAs(props.value);
          }
          return (
            <Box>
              {cloneElement(children, { ...props })}
              {showError && (
                <Text size="xs" color="red" block>
                  {error?.message}
                </Text>
              )}
            </Box>
          );
        }}
      />
    </FormGroup>
  );
};

interface FormFieldChildrenProps<T> {
  fields: (T & { id: string })[];
  append: (value?: Partial<T>) => void;
  remove: (id?: string | number) => void;
  update: (id: string | number, value: any) => void;
}

type FormFieldElementProps<T> = Omit<FormFieldChildrenProps<T>, 'fields'> & {
  value?: T;
  index?: number;
};
type FormFieldChilren<T> =
  | ((methods: FormFieldChildrenProps<T>) => React.ReactElement<HTMLElement>)
  | React.ReactElement<FormFieldElementProps<T>>;

interface FormFieldArrayProps<T = any>
  extends Omit<FormChilrenProps<T>, 'children' | 'defaultValue' | 'value'> {
  name: string;
  defaultValue?: T[];
  label?: string;
  className?: string;
  required?: boolean | string;
  children?: FormFieldChilren<T>;
  rules?: {
    validate?: (methods: UseFormReturn) => Validate<T[]> | Record<string, Validate<T[]>>;
    minLength?: {
      value: number;
      message: string;
    };
    maxLength?: {
      value: number;
      message: string;
    };
  };
}
const FieldArray = <T,>({
  name,
  children,
  rules,
  required,
  ...props
}: FormFieldArrayProps<T> & VariantProps<typeof FormGroup>) => {
  const formContext = useFormContext();
  const methods = useFieldArray({ control: formContext.control, name });

  const renderChildren = () => {
    if (!children) {
      return;
    }
    if (children instanceof Function) {
      return children(methods as any);
    } else {
      return methods.fields.map((field, index) =>
        cloneElement(children, { ...methods, index, value: field, key: field.id } as any)
      );
    }
  };

  const validate = useMemo(() => {
    let result = {} as Record<string, Validate<T[]>>;
    if (rules?.validate) {
      const validateFunc = rules?.validate(formContext);
      if (validateFunc instanceof Function) {
        result['validate'] = validateFunc;
      } else {
        result = { ...validateFunc };
      }
    }
    if (required) {
      result['required'] = value => (!!value && value.length > 0) || required;
    }
    if (rules?.maxLength) {
      result['maxLength'] = value =>
        (rules.maxLength && value.length <= rules.maxLength?.value) || rules.maxLength?.message;
    }
    if (rules?.minLength) {
      result['minLength'] = value =>
        (rules.minLength && value.length <= rules.minLength?.value) || rules.minLength?.message;
    }

    return result;
  }, [rules, formContext, required]);

  return (
    <FormGroup {...props} css={{ marginBottom: '$1' }}>
      <Controller
        rules={{
          validate: validate,
        }}
        name={name}
        render={({ fieldState: { error } }) => (
          <Box>
            {renderChildren()}
            <Text size="xs" color="red" block>
              {error?.message}
            </Text>
          </Box>
        )}
      />
    </FormGroup>
  );
};

const FormGroup = styled(
  ({
    className,
    label,
    required,
    children,
    styleLabel,
  }: {
    className?: string;
    label?: string;
    children?: ReactNode;
    required?: string | boolean;
    styleLabel?: CSSProperties;
  }) => (
    <Box className={className}>
      {label && (
        <Label className="label" style={{ ...styleLabel }}>
          {label}
          {required && <Text color="red">*</Text>}:
        </Label>
      )}

      <Box className="form-item-control">{children}</Box>
    </Box>
  ),
  {
    gap: '$1',
    flexDirection: 'column',
    justifyContent: 'left',
    '.label': {
      minWidth: 'min-content',
      width: '25%',
    },
    '.form-item-control': {
      flex: 1,
    },
    variants: {
      direction: {
        row: {
          '.label': {
            display: 'block',
          },
          display: 'block',
          '@md': { flexDirection: 'row', display: 'flex' },
        },
        column: {
          flexDirection: 'column',
          alignItems: 'stretch !important',
        },
      },
      align: {
        center: {},
        start: {},
        end: {},
        baseline: {},
      },
    },
    compoundVariants: [
      {
        direction: 'row',
        align: 'center',
        css: { alignItems: 'center' },
      },
      {
        direction: 'row',
        align: 'start',
        css: { alignItems: 'start' },
      },
      {
        direction: 'row',
        align: 'baseline',
        css: { alignItems: 'baseline' },
      },
      {
        direction: 'row',
        align: 'end',
        css: { alignItems: 'flex-end' },
      },
    ],
    defaultVariants: {
      direction: 'row',
    },
  }
);

const Form = Object.assign(StyledForm, {
  Item: Item,
  Items: FieldArray,
  Group: FormGroup,
});

export default Form;
