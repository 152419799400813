import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const FacebookIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        d="M21.8008 0H2.19922C1 0 0 1 0 2.19922V21.8008C0 23 1 24 2.19922 24H12V14H9V11H12V8C12 5.5 13 4 16 4H19V7H17.6992C16.8008 7 16 7.80078 16 8.69922V11H20L19.5 14H16V24H21.8008C23 24 24 23 24 21.8008V2.19922C24 1 23 0 21.8008 0Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(FacebookIcon);
