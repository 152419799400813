import { styled } from 'configs/stitches';

export const Text = styled('span', {
  variants: {
    block: {
      true: {
        display: 'block',
      },
    },
    italic: {
      true: {
        fontStyle: 'italic',
      },
    },
    bold: {
      true: {
        fontWeight: 'bold',
      },
    },
    underline: {
      true: {
        textDecoration: 'underline',
      },
    },
    case: {
      upper: {
        textTransform: 'uppercase',
      },
      lower: {
        textTransform: 'lowercase',
      },
      cap: {
        textTransform: 'capitalize',
      },
    },
    size: {
      xs: {
        fontSize: '$xs',
      },
      sm: {
        fontSize: '$sm',
      },
      '1': {
        fontSize: '$1',
      },
      '2': {
        fontSize: '$2',
      },
      '3': {
        fontSize: '$3',
      },
      '4': {
        fontSize: '$4',
      },
      '5': {
        fontSize: '$5',
      },
      '6': {
        fontSize: '$6',
      },
    },
    color: {
      body: {
        color: '$gray12',
      },
      muted: {
        color: '$gray9',
      },
      green: {
        color: '$green9',
      },
      red: {
        color: '$red9',
      },
    },
  },
  defaultVariants: {},
});
