import { Root } from '@radix-ui/react-label';

import { styled } from 'configs/stitches';

const Label = styled(Root, {
  variants: {
    block: {
      true: { display: 'block' },
    },
    hidden: {
      true: {
        display: 'none !important',
      },
    },
    required: {
      true: {
        '&:after': {
          content: ' *',
          color: '$red9',
        },
      },
    },
  },
});

export default Label;
