import React, { ChangeEventHandler, forwardRef, useRef } from 'react';
import { useAsync } from 'react-use';

import { styled } from 'configs/stitches';

import { Label, Box, Input, Text, Spinner } from '.';

type ValueImage<IsMulti extends boolean> = IsMulti extends true ? (File | string)[] : File | string;

type ImageUploadProps<IsMulti extends boolean> = {
  multiple?: IsMulti;
  className?: string;
  value?: ValueImage<IsMulti>;
  label?: string;
  disabled?: boolean;
  onChange?: (image: any) => void;
  maxSize?: number; // B
  showDescripion?: boolean;
  loading?: boolean;
  reset?: boolean;
  setValueAs?: (value: string) => string;
};

let fileId = 1;

const ImageUpload = forwardRef(
  <IsMulti extends boolean>(
    {
      className,
      label,
      onChange,
      value,
      maxSize = 0,
      loading,
      multiple,
      reset,
      setValueAs,
      ...props
    }: ImageUploadProps<IsMulti>,
    fileRef
  ) => {
    const maxSizeMB = maxSize * 1024 * 1024;
    const ref = useRef<HTMLDivElement>({
      style: {
        backgroundImage: typeof value === 'string' ? `url(${value})` : '',
      },
    } as HTMLDivElement);
    const errRef = useRef<HTMLSpanElement>(null);

    const id = `image-upload-${fileId++}`;

    const inputOnChange: ChangeEventHandler<HTMLInputElement> = async event => {
      const { files } = event.target;
      if (files?.length === 0) {
        return;
      }
      const filesArray: File[] = Array.prototype.slice.call(files);
      const inValid = filesArray.every(file => file.size < maxSizeMB);

      if (inValid) {
        onChange && onChange(multiple ? filesArray : filesArray[0]);
        errRef.current ? (errRef.current.innerText = '') : null;
      } else {
        errRef.current ? (errRef.current.innerText = 'The file is too large.') : null;
      }
      const image = await fileToDataURL(filesArray[0]);
      ref.current && !reset ? (ref.current.style.backgroundImage = `url(${image})`) : null;
    };

    const onDragEnter = () => ref.current && ref.current.classList.add('dragover');

    const onDragLeave = () => ref.current && ref.current.classList.remove('dragover');

    const onDrop = () => ref.current && ref.current.classList.remove('dragover');

    useAsync(async () => {
      if (typeof value === 'string') {
        const newValue = setValueAs ? setValueAs(value) : value;
        ref.current ? (ref.current.style.backgroundImage = `url(${newValue})`) : null;
      } else {
        const image = await fileToDataURL(!multiple ? value : (value as File[])[0]);
        ref.current && !reset ? (ref.current.style.backgroundImage = `url(${image})`) : null;
      }
    }, [value]);

    return (
      <Box className={className}>
        <Box
          ref={ref}
          className="input-wrapper"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          data-state={!value && 'empty'}
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Label htmlFor={id} className="btn" hidden={props.disabled}>
                <Box>{label || 'Choose File'}</Box>
              </Label>
              <Input {...props} onChange={e => e} value={value ? 1 : ''} ref={fileRef} hidden />
              <Input
                type="file"
                hidden
                onChange={inputOnChange}
                onBlur={inputOnChange}
                accept=".jpg, .jpeg, .png"
                id={id}
                multiple={multiple}
              />
            </>
          )}
        </Box>
        <Text ref={errRef} size="sm" color="red" />
      </Box>
    );
  }
);

ImageUpload.displayName = 'ImageUpload';
ImageUpload.defaultProps = {
  maxSize: 1, // 1 MB
};

export default styled(ImageUpload, {
  '.input-wrapper': {
    padding: '$2',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '$2',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    alignItems: 'center',
    backgroundColor: '$gray4',
    position: 'relative',
    height: '100%',
    width: '100%',
    '&:hover': {
      '.btn': {
        display: 'unset',
        opacity: 0.5,
      },
    },
    '.btn': {
      px: '$1',
      py: '$1',
      display: 'inline-block',
      border: 'none',
      backgroundColor: '$gray3',
      opacity: 0,
      fontSize: '$sm',
      height: 'fit-content',
      cursor: 'pointer',
      textAlign: 'center',
      transition: 'opacity 0.25s ease-in',
    },

    '&.dragover': {
      backgroundColor: '$gray3',
    },
    input: {
      position: 'absolute',
      opacity: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },

    '&[data-state=empty]': {
      '.btn': {
        opacity: 0.5,
      },
    },
  },
});

function fileToDataURL(file) {
  const reader = new FileReader();
  return new Promise<string>(function (resolve) {
    reader.onload = function (event: any) {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });
}
