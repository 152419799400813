import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const HomeIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8906 1.73959L1.8906 7.73959C1.3342 8.11052 1 8.73498 1 9.40369V21C1 22.1046 1.89543 23 3 23H7V18C7 15.2386 9.23858 13 12 13C14.7614 13 17 15.2386 17 18V23H21C22.1046 23 23 22.1046 23 21V9.40369C23 8.73498 22.6658 8.11052 22.1094 7.73959L13.1094 1.73959C12.4376 1.29172 11.5624 1.29172 10.8906 1.73959ZM9 18C9 16.3431 10.3431 15 12 15C13.6569 15 15 16.3431 15 18V21V23H9V21V18Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(HomeIcon);
