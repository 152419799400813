import React, { forwardRef } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import * as PSelect from '@radix-ui/react-select';

import { styled } from 'configs/stitches';

import { Text } from '.';

interface SelectProps extends PSelect.SelectProps {
  options: Array<string | number | { value: string; label: string }>;
  onChange?: PSelect.SelectProps['onValueChange'];
  disabled?: boolean;
  className?: string;
  prepend?: string;
  renderItem?: (i: SelectProps['options'][number]) => React.ReactNode;
}

const Select = forwardRef(
  ({ options, disabled, onChange, renderItem, className, ...props }: SelectProps, ref: any) => {
    const getValue = (option: SelectProps['options'][number]) =>
      typeof option === 'string' || typeof option === 'number' ? option : option.value;
    const getLabel = (option: SelectProps['options'][number]) =>
      typeof option === 'string' || typeof option === 'number' ? option : option.label;

    const selected = options.find(
      o => (typeof o === 'string' || typeof o === 'number' ? o : o.value) === props.value
    );

    return (
      <PSelect.Root {...props} onValueChange={onChange} value={props.value}>
        <Trigger disabled={disabled} ref={ref} className={className}>
          <PSelect.Value asChild>
            {selected && (
              <Text css={{ whiteSpace: 'nowrap' }}>
                {renderItem ? renderItem(selected) : getLabel(selected)}
              </Text>
            )}
          </PSelect.Value>
          <PSelect.Icon asChild>
            <SelectIcon />
          </PSelect.Icon>
        </Trigger>
        <Content>
          <PSelect.Viewport>
            {options.map(opt => (
              <Item
                key={getValue(opt)}
                value={getValue(opt).toString()}
                textValue={getLabel(opt).toLocaleString()}
              >
                <PSelect.ItemText>{renderItem ? renderItem(opt) : getLabel(opt)}</PSelect.ItemText>
                <ItemIndicator>
                  <CheckIcon style={{ width: '1.5em' }} />
                </ItemIndicator>
              </Item>
            ))}
          </PSelect.Viewport>
        </Content>
      </PSelect.Root>
    );
  }
);
Select.displayName = 'Select';

const SelectIcon = styled(ChevronDownIcon, {
  width: '1.5em',
});

const Trigger = styled(PSelect.Trigger, {
  all: 'unset',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 4,
  padding: '0 $1',
  boxSizing: 'border-box',
  width: '100%',
  lineHeight: 1,
  minHeight: 35,
  height: 40,
  gap: 5,
  backgroundColor: '$gray1',
  '&:hover': {
    boxShadow: `0 0 0 1px $colors$gray6`,
  },
  '&:focus': { boxShadow: `0 0 0 2px $colors$gray6` },
  variants: {
    size: {
      sm: {
        height: 32,
      },
    },
  },
});

const Content = styled(PSelect.Content, {
  backgroundColor: '$gray1',
  borderRadius: 6,
  border: '1px solid $gray5',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
});

const Item = styled(PSelect.Item, {
  all: 'unset',
  lineHeight: 1,
  display: 'flex',
  alignItems: 'center',
  height: 30,
  padding: '0 35px 0 25px',
  position: 'relative',
  userSelect: 'none',
  fontSize: '$sm',
  '&[data-disabled]': {
    color: '$gray9',
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '$gray12',
    color: '$gray1',
  },
});

const ItemIndicator = styled(PSelect.ItemIndicator, {
  position: 'absolute',
  left: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$xs',
});

export default styled(Select, {});
