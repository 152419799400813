import React, { forwardRef } from 'react';
import { VariantProps, CSS } from '@stitches/react';

import { Text } from '.';

const DEFAULT_TAG = 'h1';

type TextSizeVariants = Pick<VariantProps<typeof Text>, 'size'>;
type HeadingSizeVariants = '1' | '2' | '3' | '4' | '5' | '6';
type HeadingVariants = { size?: HeadingSizeVariants } & Omit<VariantProps<typeof Text>, 'size'>;
type HeadingProps = React.ComponentProps<typeof DEFAULT_TAG> &
  HeadingVariants & { css?: CSS; as?: any };

export const Heading = forwardRef((props: HeadingProps, forwardedRef) => {
  const { size = '1', ...textProps } = props;
  const textSize: Record<HeadingSizeVariants, TextSizeVariants['size']> = {
    1: { '@initial': '6' },
    2: { '@initial': '5' },
    3: { '@initial': '4' },
    4: { '@initial': '3' },
    5: { '@initial': '2' },
    6: { '@initial': '1' },
  };

  return (
    <Text
      as={DEFAULT_TAG}
      {...textProps}
      ref={forwardedRef}
      size={textSize[size]}
      css={{
        display: 'block',
        fontWeight: 500,
        lineHeight: 'calc(1em + 0.5rem)',
        fontVariantNumeric: 'proportional-nums',
        ...props.css,
      }}
    />
  );
});

Heading.displayName = 'Heading';
