import React from 'react';
import { Trigger, Root, Content, Arrow, Portal } from '@radix-ui/react-popover';

import { styled } from 'configs/stitches';

interface PopoverProps {
  children: React.ReactNode;
  content: React.ReactNode;
  placementOffset?: number;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
  alignOffset?: number;
  className?: string;
}

export const Popover = styled(
  ({
    className,
    content,
    children,
    placementOffset,
    placement,
    align,
    alignOffset,
    ...props
  }: PopoverProps) => (
    <Root {...props}>
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <Content
          className={className}
          sideOffset={placementOffset}
          side={placement}
          align={align || 'center'}
          alignOffset={alignOffset}
          onOpenAutoFocus={e => e.preventDefault()}
        >
          {content}
          <StyledArrow />
        </Content>
      </Portal>
    </Root>
  ),
  {
    backgroundColor: '$gray3',
    px: '$3',
    py: '$3',
    borderRadius: '$4',
    border: '1px solid $gray6',
    maxWidth: '90vw',
  }
);

const StyledArrow = styled(Arrow, {
  fill: '$gray6',
});

Popover.displayName = 'Popover';
