import React from 'react';
import {
  Root,
  Trigger,
  Content,
  Portal,
  Overlay,
  Close,
  DialogProps as RootProps,
  DialogContentProps,
} from '@radix-ui/react-dialog';
import { XMarkIcon } from '@heroicons/react/24/solid';

import { styled, keyframes } from 'configs/stitches';

import { Button, Box, Heading } from '.';

const CloseButton = styled(Button, {
  lineHeight: 1,
  padding: '$1',
  position: 'absolute',
  right: '$2',
  top: '$2',
});

export type DialogProps = RootProps & {
  className?: string;
  trigger?: React.ReactNode;
  title?: React.ReactNode;
  autoFocus?: boolean;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'auto';
};

export const Dialog = ({
  trigger,
  title,
  children,
  autoFocus,
  className,
  ...props
}: DialogProps) => (
  <Root {...props}>
    <Trigger asChild>{trigger}</Trigger>
    <DialogContent
      onOpenAutoFocus={e => !autoFocus && e.preventDefault()}
      onInteractOutside={e => e.preventDefault()}
      size={props.size}
      className={className}
    >
      <StyleHeader>
        {typeof title === 'string' ? <Heading size="4">{title}</Heading> : title}
        <Close asChild>
          <CloseButton variant="link" color="secondary">
            <XMarkIcon />
          </CloseButton>
        </Close>
      </StyleHeader>
      <Box className="dialog-content">{children}</Box>
    </DialogContent>
  </Root>
);

const DialogContent = ({
  children,
  ...props
}: DialogContentProps & {
  children: React.ReactNode;
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'auto';
}) => (
  <Portal>
    <StyledOverlay>
      <StyledContent {...props}>{children}</StyledContent>
    </StyledOverlay>
  </Portal>
);

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const StyledOverlay = styled(Overlay, {
  backgroundColor: '$grayA1',
  position: 'fixed',
  inset: 0,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  py: '$4',
  animation: `${overlayShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.96)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});

const StyledContent = styled(Content, {
  backgroundColor: '$gray3',
  borderRadius: '$4',
  width: '90vw',
  maxWidth: 'calc(480px + 10vw)',
  border: '1px solid $gray6',

  '.dialog-content': {
    px: '$3',
    py: '$3',
  },
  animation: `${contentShow} 200ms cubic-bezier(0.16, 1, 0.3, 1)`,
  '&:focus': { outline: 'none' },
  variants: {
    size: {
      xl: {
        maxWidth: '70vw',
      },
      lg: {
        width: '90vw',
      },
      md: {
        width: '70vw',
      },
      sm: {
        '@lg': { width: '30vw' },
        '@md': { width: '400px' },
      },
      auto: {
        width: 'auto',
        // maxWidth: 'fit-content',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});
const StyleHeader = styled('div', { px: '$3', paddingTop: '$3', position: 'relative' });
