import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const IdCardIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="16"
      viewBox="0 0 23 16"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 0C1.12109 0 0 1.12109 0 2.5V13.5C0 14.8789 1.12109 16 2.5 16H20.5C21.8789 16 23 14.8789 23 13.5V2.5C23 1.12109 21.8789 0 20.5 0H2.5ZM13 6C13 5.44772 13.4477 5 14 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H14C13.4477 7 13 6.55228 13 6ZM14 9C13.4477 9 13 9.44771 13 10C13 10.5523 13.4477 11 14 11H20C20.5523 11 21 10.5523 21 10C21 9.44771 20.5523 9 20 9H14ZM11 12V13H2V12C2 10.3438 3.73438 9.125 5 9H8C9.20312 9.125 11 10.3438 11 12ZM9 5.5C9 4.11719 7.88281 3 6.5 3C5.11719 3 4 4.11719 4 5.5C4 6.88281 5.11719 8 6.5 8C7.88281 8 9 6.88281 9 5.5Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(IdCardIcon);
