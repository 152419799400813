import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const UserAddIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="28"
      height="30"
      viewBox="0 0 112 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        d="M109.541 117C109.541 118.68 108.221 120 106.541 120H3.46094C1.78094 120 0.460938 118.68 0.460938 117C0.460938 92.16 24.9409 72 55.0012 72C61.1812 72 67.1812 72.84 72.7012 74.46C69.1612 78.66 67.0012 84.12 67.0012 90C67.0012 94.5 68.2606 98.76 70.4806 102.36C71.6806 104.4 73.2406 106.26 75.0406 107.82C79.2406 111.66 84.8212 114 91.0012 114C97.7212 114 103.781 111.24 108.101 106.8C109.061 110.04 109.541 113.46 109.541 117Z"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91 69C97.195 69 102.812 71.6775 106.592 75.9825C109.952 79.71 112 84.645 112 90C112 91.68 111.79 93.3075 111.37 94.8825C110.897 96.9825 110.058 99.03 108.955 100.815C105.333 106.905 98.665 111 91 111C85.5925 111 80.71 108.952 77.035 105.592C75.46 104.227 74.095 102.6 73.045 100.815C71.1025 97.665 70 93.9375 70 90C70 84.33 72.2575 79.1325 75.9325 75.3525C79.765 71.415 85.12 69 91 69ZM95 93.8218H99C101.187 93.8218 103 92.0045 103 89.8129C103 87.6214 101.187 85.804 99 85.804H95V82.0089C95 79.8174 93.1867 78 91 78C88.8133 78 87 79.8174 87 82.0089V85.804H83C80.8133 85.804 79 87.6214 79 89.8129C79 92.0045 80.8133 93.8218 83 93.8218H87V97.9911C87 100.183 88.8133 102 91 102C93.1867 102 95 100.183 95 97.9911V93.8218Z"
        fill={color}
      />
    <path
        d="M55 60C71.5684 60 85 46.5685 85 30C85 13.4315 71.5684 0 55 0C38.4315 0 25 13.4315 25 30C25 46.5685 38.4315 60 55 60Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(UserAddIcon);
