import { styled } from 'configs/stitches';

export const Input = styled('input', {
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$2',
  px: '$2',
  color: '$white',
  border: 'none',
  boxShadow: `0 0 0 1px $gray6`,
  backgroundColor: '$gray1',
  height: 40,
  '&:focus': {
    boxShadow: `0 0 0 2px $colors$gray6`,
    border: 'none',
    outline: 'none',
  },
  '&[type=checkbox]': {
    width: 'auto',
    height: 'auto',
    padding: 0,
    backgroundColor: '$gray1',
    
  },
  '&:disabled': {
    opacity: 0.8,
    backgroundColor: '$gray2',
  },
  variants: {
    size: {
      lg: {
        height: 48,
      },
      sm: {
        height: 32,
        fontSize: '$sm',
      },
      auto: {
        height: 'auto',
      },
    },
  },
});
