import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const NotAllowIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      ref={forwardedRef}
      {...props}
    >
    <path
        d="M7.7541 6.56933C8.07824 6.89347 8.07824 7.42997 7.7541 7.7541C7.58645 7.92176 7.37408 8 7.16172 8C6.94935 8 6.73699 7.92176 6.56933 7.7541L3.9986 5.18337L1.42787 7.7541C1.26022 7.92176 1.04785 8 0.835487 8C0.623123 8 0.410758 7.92176 0.243102 7.7541C-0.0810339 7.42997 -0.0810339 6.89347 0.243102 6.56933L2.81383 3.9986L0.243102 1.42787C-0.0810339 1.10374 -0.0810339 0.567237 0.243102 0.243102C0.567237 -0.0810339 1.10374 -0.0810339 1.42787 0.243102L3.9986 2.81383L6.56933 0.243102C6.89347 -0.0810339 7.42997 -0.0810339 7.7541 0.243102C8.07824 0.567237 8.07824 1.10374 7.7541 1.42787L5.18337 3.9986L7.7541 6.56933Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(NotAllowIcon);
