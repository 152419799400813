import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const CheckCircleIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24C0 10.8 10.8 0 24 0C28.8 0 33.1312 1.44375 36.975 3.6L33.375 7.2C30.4969 5.75625 27.3563 4.8 24 4.8C13.4438 4.8 4.8 13.4438 4.8 24C4.8 34.5563 13.4438 43.2 24 43.2C33.6 43.2 41.775 35.7562 42.975 26.4H47.775C46.575 38.6438 36.4781 48 24 48C10.8 48 0 37.2 0 24ZM22.05 28.275L44.4 6.9L47.775 10.35L23.775 33.375L22.05 35.025L9.825 22.8L13.2 19.425L22.05 28.275Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(CheckCircleIcon);
