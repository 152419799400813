import React, {
  ComponentProps,
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { FunnelIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { TriangleDownIcon, TriangleUpIcon } from '@radix-ui/react-icons';
import InfiniteScroll from 'react-infinite-scroll-component';

import { styled } from 'configs/stitches';

import { Box, Input, Flex, Text, Select, Pagination, Button, Popover, Checkbox, Spinner } from '.';

interface ColumnType {
  [key: string | number | symbol]: any;
}

interface DataTableProps<T extends ColumnType = any> {
  rowId: keyof T;
  columns: Column<T>[];
  rows: T[];
  className?: string;
  loading?: boolean;
  pagination?: TablePaginationProps;
  allowSelect?: boolean;
  headerRender?: (value) => ReactNode;
  footerRender?: (value) => ReactNode;
}
// show: !!pagination
// controled: page && on PageChange|| size && onPageSizeChange
type TablePaginationProps = {
  page?: number;
  total?: number;
  size?: number;
  defaultSize?: TablePaginationProps['size'];
  onChange?: ({ page, size }: { page: number; size: number }) => void;
  position?: 'top' | 'bottom';
  onLoadMore?: () => void;
  hasMore?: boolean;
};

interface TabelContextModel {
  selected: string[];
  controlled?: boolean;
  query?: any;
  loading?: boolean;
  pagination?: DataTableProps['pagination'];
  onSelect: (value?: string) => void;
  onRemoveSelect: (value?: string) => void;
  onSelectAll: (selected: boolean) => void;
}

const TableContext = createContext<TabelContextModel>({} as any);
const useTableContext = () => useContext(TableContext);

const DataTable = <T extends ColumnType>({
  columns,
  rows,
  rowId,
  className,
  allowSelect,
  pagination,
  loading,
}: DataTableProps<T> & ComponentProps<typeof WrapTable>) => {
  const [selected, setSelected] = useState<Array<string>>([]);
  const [tableState, setTableState] = useState({
    pageIndex: 1,
    pageSize: pagination?.defaultSize || 5,
  });

  const onSelect = value => {
    setSelected([...selected, value]);
  };
  const onRemoveSelect = value => {
    setSelected(selected.filter(v => v !== value));
  };
  const onSelectAll = (isSelected = true) => {
    if (isSelected && !selected.length) {
      const newSelected = _.uniq([...selected, ...pageRows.map(row => row[rowId] as any)]);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  let paginationCtx: TabelContextModel['pagination'] = {};

  if (pagination?.onLoadMore === undefined) {
    paginationCtx = {
      total: pagination?.total || rows.length,
      page: pagination?.page || tableState.pageIndex,
      size:
        pagination?.total && pagination?.size && pagination.total <= pagination.size
          ? rows.length
          : pagination?.size || tableState.pageSize || rows.length,
      onChange: ({ page, size }: { page: number; size: number }) => {
        setTableState({ ...tableState, pageIndex: page });
        pagination?.onChange && pagination.onChange({ page, size });
      },
    };
  }

  const { size = rows.length, page = 1 } = paginationCtx || {};

  const controlled = pagination && pagination.page && pagination.onChange;
  const pageRows = useMemo(() => {
    if (controlled) {
      return rows;
    }
    return rows.filter((_, i) => Math.ceil((i + 1) / size) === page);
  }, [size, page, rows, controlled]);

  const isSelectedAll = pageRows && pageRows.every(value => selected.includes(value[rowId] as any));

  const ctx: TabelContextModel = {
    selected,
    onSelect,
    onRemoveSelect,
    onSelectAll,
    pagination: paginationCtx,
    loading,
  };

  const renderTable = (
    <table>
      <TableHeader columns={columns} allowSelect={allowSelect} isSelectedAll={isSelectedAll} />
      <TableBody
        columns={columns}
        rows={pageRows || []}
        allowSelect={allowSelect}
        rowId={rowId}
        startIndex={!controlled ? (page - 1) * size : 0}
      />
    </table>
  );

  if (pagination?.onLoadMore === undefined) {
    return (
      <>
        <TableContext.Provider value={ctx}>
          <WrapTable className={className}>{renderTable}</WrapTable>
          {!(pagination?.total && pagination?.size && pagination.total <= pagination.size) && (
            <TableFooter />
          )}
        </TableContext.Provider>
      </>
    );
  }

  return (
    <TableContext.Provider value={ctx}>
      <WrapTable className={className} id="scrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={pagination?.onLoadMore}
          hasMore={pagination.hasMore || false}
          loader={
            <Box css={{ textAlign: 'center', marginTop: '8px' }}>
              <Spinner />
            </Box>
          }
          scrollableTarget="scrollableDiv"
        >
          {renderTable}
        </InfiniteScroll>
      </WrapTable>
    </TableContext.Provider>
  );
};

type TableHeaderProps<T extends ColumnType> = Pick<DataTableProps<T>, 'columns' | 'allowSelect'> & {
  isSelectedAll?: boolean;
};

const TableHeader = <T extends ColumnType>({
  columns,
  isSelectedAll,
  allowSelect,
}: TableHeaderProps<T>) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { onSelectAll, selected } = useTableContext();

  if (checkboxRef.current) {
    checkboxRef.current.indeterminate = !isSelectedAll && selected?.length > 0;
  }

  return (
    <thead>
      <tr>
        {allowSelect && (
          <th>
            <Input
              type="checkbox"
              ref={checkboxRef}
              checked={isSelectedAll}
              onChange={e => onSelectAll(e.target.checked)}
            />
          </th>
        )}
        {columns.map(column => (
          <th key={column.key}>{column.title}</th>
        ))}
      </tr>
    </thead>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SearchInput = () => (
  <Popover
    css={{ padding: 10, borderRadius: 3 }}
    content={
      <Flex gap="1">
        <Input size="sm" css={{ height: 'auto' }} placeholder="search" />
        <Button size="sm">Search</Button>
      </Flex>
    }
  >
    <Button
      variant="link"
      css={{
        fontSize: 'x-small',
        padding: 0,
        '&:focus': {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
      }}
    >
      <MagnifyingGlassIcon />
    </Button>
  </Popover>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FilterInput = () => (
  <Popover
    css={{ padding: 10, borderRadius: 3 }}
    content={
      <Flex gap="1" direction="column">
        <Checkbox size="sm">All</Checkbox>
        <Checkbox size="sm">Private sale Project</Checkbox>
        <Checkbox size="sm">IDO Project</Checkbox>
        <Flex gap="1">
          <Button size="sm" color="secondary">
            Reset
          </Button>
          <Button size="sm">Filter</Button>
        </Flex>
      </Flex>
    }
  >
    <Button
      variant="link"
      css={{
        fontSize: 'x-small',
        padding: 0,
        '&:focus': {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
      }}
    >
      <FunnelIcon />
    </Button>
  </Popover>
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SortButton = () => (
  <Button
    variant="link"
    css={{
      fontSize: 'x-small',
      padding: 0,
      svg: { my: -3 },
      '&:focus': {
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
      },
    }}
  >
    <Flex direction="column" justify="center">
      <TriangleUpIcon width={10} height={10} />
      <TriangleDownIcon width={10} height={10} />
    </Flex>
  </Button>
);

type TableBodyProps<T extends ColumnType> = Pick<
  DataTableProps<T>,
  'columns' | 'rowId' | 'rows' | 'allowSelect'
> & {
  startIndex: number;
};
const TableBody = <T extends ColumnType>({
  rows,
  rowId,
  startIndex,
  ...props
}: TableBodyProps<T>) => (
  <tbody>
    {rows.map((row, index) => (
      <TableRow
        row={row}
        key={row[rowId] as any}
        rowId={rowId}
        index={startIndex + index}
        {...props}
      />
    ))}
  </tbody>
);

type TableRowProps<T extends ColumnType> = Pick<
  DataTableProps,
  'columns' | 'allowSelect' | 'rowId'
> & {
  row: T;
  index: number;
};
const TableRow = <T extends ColumnType>({
  index,
  row,
  columns,
  allowSelect,
  rowId,
}: TableRowProps<T>) => {
  const { selected, onSelect, onRemoveSelect } = useTableContext();
  const onSelectChange = (checked: boolean) => {
    checked ? onSelect(row[rowId]) : onRemoveSelect(row[rowId]);
  };

  return (
    <tr>
      {allowSelect && (
        <td>
          <Input
            type="checkbox"
            checked={selected.includes(row[rowId])}
            onChange={e => onSelectChange(e.target.checked)}
          />
        </td>
      )}

      {columns.map(column => (
        <td key={column.key}>{column.render ? column.render(row, index) : row[column.key]}</td>
      ))}
    </tr>
  );
};

const TableFooter = () => {
  const { pagination, loading } = useTableContext();
  const { size = 5, page = 1, total = 0, onChange } = pagination || {};

  const startIndex = size * (page - 1) + 1;
  const endIndex = size * page > total ? total : size * page;

  const pageSizeOptions = [5, 10, 15, 20];

  return (
    <Box css={{ marginTop: '$1' }}>
      <Flex justify="between" align="center" wrap="wrap">
        <Text size="sm">
          {startIndex} - {endIndex} of {pagination?.total}
        </Text>
        {loading && <Spinner size="sm" />}
        <Flex align="center" wrap="wrap" justify="center">
          <Select
            options={pageSizeOptions?.map(o => o.toString()) || []}
            value={(pagination?.size || 5).toString()}
            renderItem={v => `${v} / page`}
            onChange={row =>
              onChange &&
              onChange({
                page: 1,
                size: Number(row) as any,
              })
            }
            css={{
              width: 'auto',
            }}
          />
          <Pagination
            index={pagination?.page || 1}
            maxItems={5}
            pages={Math.ceil(total / size)}
            onPageChange={page => onChange && onChange({ page, size })}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

type SortOrder = 'asc' | 'desc' | undefined;
type FilterMode = 'onChange' | 'onSubmit';
export interface Column<T extends ColumnType = any> {
  key: string;
  title?: string;
  render?: (data: T, index: number) => ReactNode;
  width?: number;
  colSpan?: number;
  rowSpan?: number;
  sort?: {
    orderBy?: SortOrder;
    onSort: (type: SortOrder) => void;
  };
  search?:
    | {
        key?: string;
        onChange: (key: string) => void;
        mode?: FilterMode;
        placeHolder?: string;
      }
    | ReactNode;
  filter?:
    | {
        options: Array<string | { label: string; value: string }>;
        defaultOption: string;
        onFilterChange: (value: string) => void;
        placeHolder?: string;
      }
    | ReactNode;
}

export default DataTable;

const WrapTable = styled(Box, {
  overflowX: 'auto',
  table: {
    borderCollapse: 'collapse',
    minWidth: '100%',
    width: 'max-content',
    border: '1px solid $gray5',
    'td,th': {
      border: '1px solid $gray5',
      padding: '$2',
      textAlign: 'center',
    },
  },
});

/**
 * ==== Data Table ====
 *
 * loading
 * rows : T
 * column:
 *    key: keyof T
 *    name: string
 *    render: (value: T) => JSX
 *    options: {
 *              type: filter | sort | search
 *              options:[] // filter-option
 *              onChange:() => void
 *             }
 *    width: number
 *
 * --- pagination ---
 * total:number
 * pageSize: number
 * pageIndex: number
 * onPageChange:(page) => T[]
 * onRowPerPageChange:(row) => void
 *
 * ---- index ----
 * allowIndex: boolean
 *
 * ---- selected ----
 * allowSelect: boolean
 * // indeterminate//
 *
 *
 * ---- search global ----
 * onSearch : (key?:string) => void
 * searchMode : onChange|onSubmit
 * controlled:boolean
 *
 *
 */
