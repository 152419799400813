import React from 'react';
import { Provider as TooltipProvider, TooltipProviderProps } from '@radix-ui/react-tooltip';

interface ProviderProps {
  config?: {
    tooltip?: TooltipProviderProps;
  };
  children: React.ReactNode;
}
const Provider: React.FC<ProviderProps> = ({ config, children }) => (
  <TooltipProvider {...config?.tooltip}>{children}</TooltipProvider>
);

export default Provider;
