import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const LockIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3272 6.47521V8.31522C20.3941 8.96035 21.9 10.8313 21.9 13.0661V18.9904C21.9 21.757 19.6063 24 16.7786 24H6.62256C3.79363 24 1.5 21.757 1.5 18.9904V13.0661C1.5 10.8313 3.00714 8.96035 5.07275 8.31522V6.47521C5.08494 2.89775 8.048 0 11.6818 0C15.3642 0 18.3272 2.89775 18.3272 6.47521ZM11.7061 2.08685C14.1814 2.08685 16.1934 4.05445 16.1934 6.47521V8.05644H7.20664V6.45136C7.21883 4.04254 9.23078 2.08685 11.7061 2.08685ZM12.7669 17.3459C12.7669 17.9303 12.2914 18.3953 11.6939 18.3953C11.1086 18.3953 10.6331 17.9303 10.6331 17.3459V14.6986C10.6331 14.1262 11.1086 13.6612 11.6939 13.6612C12.2914 13.6612 12.7669 14.1262 12.7669 14.6986V17.3459Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(LockIcon);
