import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const StakeIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46877 20.9968C10.367 20.9968 11.2653 20.9918 12.1633 20.9985C12.8472 21.0036 13.4764 20.8467 14.0711 20.5227C15.9531 19.498 17.8424 18.4852 19.7286 17.4674C20.9647 16.8005 22.2023 16.136 23.435 15.4637C23.79 15.27 24.0371 14.9922 23.9954 14.577C23.9536 14.162 23.6579 13.9739 23.2568 13.8676C22.4285 13.6481 21.5919 13.5947 20.7721 13.8378C20.1714 14.0158 19.5947 14.2721 19.0195 14.5189C17.6199 15.1193 16.2285 15.737 14.8304 16.3407C14.6609 16.4139 14.4712 16.4847 14.2896 16.4874C13.2435 16.5035 12.197 16.5067 11.1509 16.4967C10.8163 16.4936 10.5693 16.2231 10.5622 15.9153C10.5555 15.6199 10.7823 15.3637 11.1033 15.313C11.2001 15.2978 11.3001 15.2961 11.3986 15.2959C12.2473 15.2947 13.0961 15.2963 13.9449 15.2947C14.3684 15.2938 14.6177 15.1434 14.6868 14.7577C14.7341 14.4926 14.7158 14.2 14.6477 13.938C14.5647 13.6193 14.3345 13.5 13.9809 13.4958C12.8356 13.4822 11.6904 13.4616 10.5452 13.4608C10.053 13.4604 9.57618 13.4075 9.11629 13.242C8.1137 12.881 7.13017 12.92 6.17743 13.3854C5.11293 13.9055 4.47766 14.7803 4.0121 15.7842C3.98249 15.848 4.01276 15.9498 4.04355 16.0234C4.7104 17.6187 5.38405 19.2114 6.04681 20.8082C6.11004 20.9605 6.19134 21.001 6.35406 21C7.39225 20.9937 8.43051 20.9968 9.46877 20.9968"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.78965 23.3785C3.24895 23.7005 2.54875 23.449 2.33652 22.8566L0.276698 17.1063C0.115841 16.6573 0.29453 16.1571 0.703517 15.9117L1.18175 15.6246C1.72115 15.3009 2.42193 15.5497 2.63643 16.1411L4.71813 21.8805C4.88172 22.3315 4.70196 22.8351 4.28977 23.0806L3.78965 23.3785Z"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2613 6.01379L18.0025 7.27267C17.7131 7.5621 17.2438 7.5621 16.9544 7.27267L15.6955 6.01379C15.406 5.72437 15.406 5.25512 15.6955 4.96569L16.9544 3.70681C17.2438 3.41739 17.7131 3.41739 18.0025 3.70681L19.2613 4.96569C19.5508 5.25512 19.5508 5.72437 19.2613 6.01379M17.4667 0.000148231C14.4734 0.00677323 12.0566 2.46665 12.062 5.50121C12.0675 8.5389 14.4913 10.987 17.4861 10.9793C20.4835 10.9716 22.8992 8.5164 22.8949 5.48177C22.8907 2.45227 20.4558 -0.00641427 17.4667 0.000148231"
        fill={color}
      />
  </svg>
  );

export default forwardRef(StakeIcon);
