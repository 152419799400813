import React from 'react';
import { Root, Indicator, CheckboxProps } from '@radix-ui/react-checkbox';
import { Label } from '@radix-ui/react-label';
import { CheckIcon } from '@heroicons/react/24/outline';
import { VariantProps } from '@stitches/react';

import { styled } from 'configs/stitches';
import { Flex } from 'components/ui';

let id = 0;

export const Checkbox = styled(
  ({
    className,
    children,
    ...props
  }: {
    className?: string;
    children?: React.ReactNode;
  } & CheckboxProps &
    VariantProps<typeof StyledCheckbox>) => {
    const cid = props?.id || `Checkbox-${++id}`;
    return (
      <Flex gap="1" className={className} align="start">
        <StyledCheckbox id={cid} {...props}>
          <Indicator>
            <CheckIcon />
          </Indicator>
        </StyledCheckbox>
        <Label style={{ userSelect: 'none' }} htmlFor={cid}>
          {children}
        </Label>
      </Flex>
    );
  }
);

const StyledCheckbox = styled(Root, {
  all: 'unset',
  backgroundColor: '$gray12',
  borderRadius: 3,
  color: '$gray1',
  marginTop: 2,
  '& svg': {
    display: 'block',
  },
  flex: '0 0 auto',
  variants: {
    size: {
      sm: {
        width: 16,
        height: 16,
        fontSize: '$sm',
      },
      md: {
        width: 20,
        height: 20,
      },
      lg: {},
    },
    rounded: {
      true: {
        borderRadius: '100%',
      },
    },
    outline: {
      true: {
        background: 'transparent',
        border: '2px solid $gray12',
        color: '$gray12',
        '&:hover': {
          backgroundColor: '$gray5',
        },
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
