import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const ASTARIcon = ({ ...props }: IconProps, forwardedRef) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} ref={forwardedRef} viewBox="0 0 512 512">
    <g id="Icon-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ASTAR">
        <g id="CIRCLE">
          <path
              d="M512,256 C512,397.385547 397.385547,512 256,512 C114.614453,512 0,397.385547 0,256 C0,114.614453 114.614453,0 256,0 C397.385547,0 512,114.614453 512,256"
              id="BG"
              fill="#254FA3"
            />
          <path
              d="M256,32 C379.711784,32 480,132.288216 480,256 C480,379.711784 379.711784,480 256,480 C132.288216,480 32,379.711784 32,256 C32,132.288216 132.288216,32 256,32 Z M256,48 C141.124772,48 48,141.124772 48,256 C48,370.875228 141.124772,464 256,464 C370.875228,464 464,370.875228 464,256 C464,141.124772 370.875228,48 256,48 Z"
              id="LINE"
              fill="#FFFFFF"
            />
        </g>
        <g id="Combined-Shape" transform="translate(139.000000, 111.000000)" fill="#FFFFFF">
          <path d="M162.857908,90.5302575 L232.579703,234.161483 C232.970267,234.939083 233.165549,235.911083 233.165549,237.071408 C233.165549,240.187883 231.212728,241.743083 227.307087,241.743083 L227.307087,241.743083 L200.651087,241.743083 C198.698267,241.743083 197.184831,241.354283 196.110779,240.576683 C195.036728,239.805158 194.011497,238.535483 193.035087,236.785883 L193.035087,236.785883 L185.821856,221.446508 C178.425549,205.718333 162.552779,195.670283 145.111651,195.670283 L145.111651,195.670283 L96.9685231,195.670283 L162.857908,90.5302575 Z M197.820718,34.73685 L52.0487692,213.639525 L52.1891282,213.639525 C50.3461538,216.081675 48.6557436,218.645325 47.3253846,221.48235 L47.3253846,221.48235 L40.1304615,236.785275 C38.7634872,240.09615 36.2248205,241.742475 32.5144615,241.742475 L32.5144615,241.742475 L5.56553846,241.742475 C3.808,241.742475 2.44102564,241.256475 1.46461538,240.284475 C0.488205128,239.312475 -5.01023724e-14,238.146075 -5.01023724e-14,236.785275 C-5.01023724e-14,235.424475 0.195282051,234.355275 0.585846154,233.5716 L0.585846154,233.5716 L46.4527179,139.6764 L94.0283077,42.282 C94.8094359,40.5324 95.7309231,39.323475 96.8110769,38.637 C97.8851282,37.9566 99.4962051,37.6164 101.644308,37.6164 L101.644308,37.6164 L131.815385,37.6164 C135.525744,37.6164 138.06441,39.1716 139.431385,42.282 L139.431385,42.282 L151.39241,66.9222 L197.820718,34.73685 Z M226.380108,0.0018225 L219.716108,36.6887475 L209.714005,20.3591475 L192.742774,12.4312725 L226.380108,0.0018225 Z" />
        </g>
      </g>
    </g>
  </svg>
  );

export default forwardRef(ASTARIcon);
