import { keyframes } from 'configs/stitches';

const VIEWPORT_PADDING = 25;

export const SlideIn = {
  'right-left': keyframes({
    '0%': { transform: `translateX(110%)`, maxHeight: 0 },
    '50%': { transform: `translateX(110%)`, maxHeight: 999 },
    '100%': { transform: 'translateX(0)' },
  }),
  'left-right': keyframes({
    '0%': { transform: `translateX(-110%)`, maxHeight: 0 },
    '50%': { transform: `translateX(-110%)`, maxHeight: 999 },
    '100%': { transform: 'translateX(0)' },
  }),
  'top-bottom': {
    from: { transform: 'translateY(0)' },
    to: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
  },
  'bottom-top': {
    from: { transform: 'translateY(0)' },
    to: { transform: `translateY(calc(100% + ${VIEWPORT_PADDING}px))` },
  },
};
export const SlideOut = {
  'right-left': keyframes({
    '0': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(110%)' },
    '100%': { transform: 'translateX(110%)', maxHeight: 0 },
  }),
  'left-right': keyframes({
    '0': { transform: 'translateX(0)' },
    '50%': { transform: 'translateX(-110%)' },
    '100%': { transform: 'translateX(-110%)', maxHeight: 0 },
  }),
};
export const SlideCancel = {};
export const SlideEnd = {
  'right-left': keyframes({
    // from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
    // to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  }),
  'left-right': keyframes({
    // from: { transform: `translateX(0)` },
    // to: { transform: 'translateX(-300px)' },
  }),
};
