import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const TwitterIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        d="M24 5.22286C23.1153 5.60114 22.1669 5.85513 21.17 5.97042C22.1875 5.38318 22.9694 4.45368 23.336 3.34766C22.3858 3.88986 21.329 4.28436 20.2086 4.49692C19.3107 3.57463 18.0313 3 16.6154 3C13.8957 3 11.6904 5.12198 11.6904 7.74114C11.6904 8.11221 11.7353 8.47428 11.8195 8.82194C7.72691 8.62379 4.10007 6.73599 1.67033 3.86645C1.24573 4.56897 1.00444 5.38318 1.00444 6.25143C1.00444 7.89605 1.87234 9.34793 3.19476 10.2C2.38672 10.1748 1.62731 9.96039 0.963292 9.60553C0.963292 9.62534 0.963292 9.64516 0.963292 9.66497C0.963292 11.9635 2.66168 13.8783 4.91185 14.316C4.50035 14.4241 4.06453 14.4818 3.61562 14.4818C3.29764 14.4818 2.98901 14.4529 2.68787 14.3953C3.31634 16.2777 5.13444 17.6521 7.28735 17.69C5.60393 18.9617 3.48094 19.7183 1.17278 19.7183C0.774375 19.7183 0.383447 19.6967 0 19.6516C2.17723 20.999 4.76596 21.7826 7.54735 21.7826C16.6042 21.7826 21.5572 14.5574 21.5572 8.29235C21.5572 8.08699 21.5516 7.87984 21.5441 7.67809C22.5055 7.00979 23.3397 6.17397 24 5.22286Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(TwitterIcon);
