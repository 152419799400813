import React from 'react';
import {
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';

import { styled } from 'configs/stitches';
import { Box, Flex, Heading } from 'components/ui';

interface TimelineProps {
  className?: string;
  children: React.ReactNode; // typeof Item | Array<typeof Item>;
}
const StyledTimeline: React.FC<TimelineProps & TimelinItemProps> = styled(
  ({ className, children }) => (
    <Box className={className}>
      <Box className="line" />
      {children}
    </Box>
  ),
  {
    $$iconSize: '26px',
    position: 'relative',
    '.line': {
      position: 'absolute',
      backgroundColor: '$gray5',
      width: 4,
      borderRadius: '$10',
      zIndex: 1,
    },
    variants: {
      layout: {
        vertical: {
          paddingLeft: 'calc($$iconSize + $2)',
          '.line': {
            left: '$2',
            top: 5,
            bottom: 10,
          },
        },
        horizontal: {},
      },
    },
    defaultVariants: {
      layout: 'vertical',
    },
  }
);

StyledTimeline.displayName = 'Timeline';

interface TimelinItemProps {
  className?: string;
  title?: React.ReactNode;
  children?: React.ReactNode;
  status?: 'success' | 'error' | 'warning' | 'pending' | 'inprogress';
}

const Item = styled(
  ({ className, title, children, status = 'pending' }: TimelinItemProps) => (
    <Box className={className}>
      <Flex className="heading" align="center" gap={2}>
        <Icon status={status} variant={status} />
        <Heading size="6">{title}</Heading>
      </Flex>
      <Box>{children}</Box>
    </Box>
  ),
  {
    zIndex: 2,
    position: 'relative',
    marginBottom: '$2',
    '.heading': {
      marginLeft: 'calc(-$$iconSize/2 - 24px)',
    },
  }
);
Item.displayName = 'TimelineItem';

export const Timeline: typeof StyledTimeline & { Item: typeof Item } = Object.assign(
  StyledTimeline,
  { Item }
);

const icons = {
  success: <CheckCircleIcon />,
  error: <XCircleIcon />,
  warning: <ExclamationCircleIcon />,
  inprogress: <EllipsisHorizontalCircleIcon />,
  pending: '',
};

const Icon = styled(
  ({ status, className }: { status: keyof typeof icons; className?: string }) => (
    <div className={className}>{icons[status]}</div>
  ),
  {
    color: '$gray5',
    position: 'relative',
    width: '$$iconSize',
    height: '$$iconSize',
    '& svg': {
      position: 'relative',
      width: '100%',
      zIndex: 2,
    },
    '&::before': {
      content: '',
      position: 'absolute',
      width: 18,
      height: 18,
      top: 4,
      left: 4,
      borderRadius: '100%',
      backgroundColor: '$white',
      zIndex: 1,
    },
    variants: {
      variant: {
        success: {
          color: '$green9',
        },
        warning: {
          color: '$orange9',
        },
        error: {
          color: '$red9',
        },
        inprogress: {
          color: '$blue9',
        },
        pending: {
          '&::before': {
            border: '1px solid $gray3',
            backgroundColor: '$gray6',
            width: 16,
            height: 16,
            top: 5,
            left: 5,
          },
        },
      },
    },
    defaultVariants: {
      variant: 'pending',
    },
  }
);
Icon.displayName = 'TimelineIcon';
