import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import locFormat from 'dayjs/plugin/localizedFormat';
import weekDay from 'dayjs/plugin/weekday';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { SWRConfig, SWRHook } from 'swr';
import { Buffer } from 'buffer';

import { sentry } from 'configs';

import AppLoader from './AppLoader';

import './index.css';

window.Buffer = Buffer;

const plugin = (options, dayjsClass) => {
  let diff = 0;
  (async () => {
    const res = await fetch('/_now');
    const now = res.headers.get('x-now');
    if (!now) return;
    const st = new Date(now.replaceAll('-', ' ')).getTime();
    const ct = new Date().getTime();
    if (Math.abs(st - ct) > 3000) {
      diff = st - ct;
    }
  })();

  const parse = dayjsClass.prototype.parse;
  dayjsClass.prototype.parse = function (cfg) {
    if (!cfg.date && diff) {
      cfg.date = new Date(new Date().getTime() + diff);
    }
    parse.bind(this)(cfg);
  };
};

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(locFormat);
dayjs.extend(plugin);
dayjs.extend(weekDay);
dayjs.extend(isToday);
dayjs.extend(utc);

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: sentry.dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: import.meta.env.VITE_RELEASE_HASH,
  });
}

const swrLogger = (useSWRNext: SWRHook) => (key, fetcher, config) => {
  const nextFetcher = async (...args) => {
    if (config.initialSize) {
      for (let i = 0; i < config.initialSize; i++) {
        console.debug('SWR fetch:', key(i));
      }
    } else {
      console.debug('SWR fetch:', typeof key === 'function' ? key() : key);
    }
    try {
      return await fetcher(...args);
    } catch (e) {
      console.error('SWR fetch:', args, e);
      throw e;
    }
  };
  return useSWRNext(key, nextFetcher, config);
};

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <SWRConfig value={{ use: [swrLogger] }}>
        <AppLoader />
      </SWRConfig>
    </BrowserRouter>
  </React.StrictMode>
);
