import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const YoutubeIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8244 3.66478C22.0511 3.94196 23.1082 4.82978 23.3301 6.04891C23.6085 7.43478 23.8869 9.375 24 11.8696C23.9434 14.3078 23.665 16.3043 23.4432 17.6902C23.2191 18.9656 22.162 19.7972 20.9375 20.0743C18.9886 20.462 15.6476 20.7391 11.9717 20.7391C8.29581 20.7391 4.95487 20.462 3.00598 20.0743C1.7814 19.7972 0.724307 18.9093 0.500272 17.6902C0.22186 16.3043 0 14.3641 0 11.8696C0 9.375 0.22186 7.43478 0.445894 6.04891C0.667754 4.77348 1.72703 3.94196 2.9516 3.66478C4.78956 3.27717 8.24144 3 11.9152 3C15.5911 3 18.9886 3.27717 20.8244 3.66478ZM15.702 11.8696L8.90919 7.98913V15.75L15.702 11.8696Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(YoutubeIcon);
