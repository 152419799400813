import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const LinkIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0479 1.99039C15.1657 0.872598 16.6116 0.216348 18.1152 0.144232C19.6837 0.0721171 21.1657 0.627406 22.269 1.73077C23.3003 2.76202 23.8556 4.15745 23.8556 5.625C23.8556 7.20433 23.1885 8.77644 22.0094 9.95192L17.3652 14.5673C16.1573 15.7825 14.5815 16.4135 13.0383 16.4135C11.6104 16.4135 10.2258 15.8798 9.17289 14.8269C8.58515 14.2392 8.58515 13.2801 9.17289 12.6923C9.76063 12.1046 10.7234 12.1082 11.3075 12.6923C12.3315 13.7163 14.0984 13.6226 15.2594 12.4615L19.8748 7.81731C20.495 7.20072 20.8556 6.41467 20.8556 5.625C20.8556 5.15265 20.7294 4.46034 20.1344 3.86539C19.4962 3.22717 18.7714 3.11899 18.2594 3.14423C17.5058 3.17668 16.7702 3.53726 16.1825 4.125L13.5287 6.77885C12.9092 6.15939 10.358 5.68383 9.66574 6.37254L14.0479 1.99039ZM6.60558 9.43269C7.81712 8.22116 9.41808 7.58654 10.9614 7.58654C12.3856 7.58654 13.7739 8.12019 14.8267 9.17308C15.4145 9.76082 15.4145 10.72 14.8267 11.3077C14.2426 11.8954 13.2799 11.8954 12.6921 11.3077C11.6681 10.2873 9.90126 10.3774 8.7402 11.5385L4.12481 16.1827C3.50462 16.7993 3.14404 17.5853 3.14404 18.375C3.14404 18.8438 3.27025 19.5397 3.8652 20.1346C4.50342 20.7728 5.22818 20.881 5.7402 20.8558C6.4938 20.8197 7.22938 20.4627 7.81712 19.875L10.471 17.2212C11.0912 17.8413 13.644 18.3173 14.3364 17.625L9.95174 22.0096C8.83395 23.1274 7.38803 23.7837 5.88443 23.8558C4.31592 23.9279 2.83395 23.3726 1.73058 22.2692C0.699332 21.238 0.144043 19.8426 0.144043 18.375C0.144043 16.7957 0.81111 15.2236 1.9902 14.0481L6.60558 9.43269Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(LinkIcon);
