import { styled } from 'configs/stitches';

export const Badge = styled('div', {
  borderRadius: 100,
  px: '$1',
  lineHeight: '$fontSizes$1',
  whiteSpace: 'nowrap',
  variants: {
    size: {
      sm: {
        fontSize: '$xs',
        padding: '3px $2',
      },
    },
    color: {
      gray: {
        backgroundColor: '$gray3',
        border: '1px solid $gray6',
      },
      green: {
        backgroundColor: '$green3',
        border: '1px solid $green6',
      },
      red: {
        backgroundColor: '$red3',
        border: '1px solid $red6',
      },
      orange: {
        backgroundColor: '$orange3',
        border: '1px solid $orange6',
      },
    },
  },
  defaultVariants: {
    color: 'gray',
    size: 'sm',
  },
});
