import React from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';

import { css, styled } from 'configs/stitches';

import { Box, Flex } from '.';

const icons = {
  success: <CheckCircleIcon />,
  error: <ExclamationTriangleIcon />,
  warning: <ExclamationCircleIcon />,
};

interface AlertProps {
  type: 'success' | 'error' | 'warning';
  action?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

const Alert: React.FC<AlertProps> = ({ type, action, children, className }) => {
  const style = getStyle(type);

  return (
    <Flex align="center" className={classNames(className, style())} gap={1}>
      <Box className="icon">{icons[type || '']}</Box>
      <Box css={{ wordBreak: 'break-all' }}>{children}</Box>
      <Box css={{ marginLeft: 'auto' }}>{action}</Box>
    </Flex>
  );
};

export default styled(Alert, {
  overflow: 'hidden',
  backgroundColor: '$gray3',
  borderRadius: '$3',
  padding: '$2',
  '.icon': {
    display: 'inline-flex',
    // height: '1.5em',
    width: '1.5em',
    flexShrink: 0,
  },
  variants: {
    size: {
      sm: {
        fontSize: '$sm',
        padding: '$1',
      },
    },
  },
});

Alert.displayName = 'Alert';

const colors = {
  success: 'green',
  warning: 'orange',
  error: 'red',
};

function getStyle(type: AlertProps['type']) {
  const color = colors[type || ''];
  return css({
    color: `$${color}11`,
    backgroundColor: `$${color}3 !important`,
  });
}
