import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const BoxIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      {...props}
      ref={forwardedRef}
    >
    <path
        d="M9.10415 2.9107L5.25415 5.1407C5.09915 5.2307 4.90415 5.2307 4.74415 5.1407L0.894131 2.9107C0.619131 2.7507 0.549131 2.3757 0.759131 2.1407C0.904131 1.9757 1.06913 1.8407 1.24413 1.7457L3.95413 0.245703C4.53415 -0.0792969 5.47415 -0.0792969 6.05415 0.245703L8.76415 1.7457C8.93915 1.8407 9.10415 1.9807 9.24915 2.1407C9.44915 2.3757 9.37915 2.7507 9.10415 2.9107Z"
        fill={color}
      />
    <path
        d="M4.71525 6.06945V9.47945C4.71525 9.85945 4.33025 10.1095 3.99023 9.94445C2.96023 9.43945 1.22523 8.49445 1.22523 8.49445C0.615234 8.14945 0.115234 7.27945 0.115234 6.56445V3.98445C0.115234 3.58945 0.530234 3.33945 0.870234 3.53445L4.46525 5.61945C4.61525 5.71445 4.71525 5.88445 4.71525 6.06945Z"
        fill={color}
      />
    <path
        d="M5.28516 6.06945V9.47945C5.28516 9.85945 5.67016 10.1095 6.01016 9.94445C7.04016 9.43945 8.77516 8.49445 8.77516 8.49445C9.38516 8.14945 9.88516 7.27945 9.88516 6.56445V3.98445C9.88516 3.58945 9.47016 3.33945 9.13016 3.53445L5.53516 5.61945C5.38516 5.71445 5.28516 5.88445 5.28516 6.06945Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(BoxIcon);
