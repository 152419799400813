import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const AllowIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="6"
      viewBox="0 0 9 6"
      ref={forwardedRef}
      {...props}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69003 0.75L4.00806 5.875C3.98834 5.94444 3.86576 5.99755 3.73265 6C3.60583 6.00228 3.48102 5.95368 3.45724 5.875L0.565439 3C0.395434 2.86741 0.389707 2.64405 0.565439 2.5L0.703144 2.375C0.82403 2.16574 1.0906 2.13518 1.25396 2.25L3.31954 3.625C3.48047 3.71061 3.72161 3.69678 3.87036 3.5L7.8638 0.125C8.08745 -0.0304834 8.34334 -0.0375439 8.55232 0.125L8.69003 0.25C8.85578 0.350681 8.88311 0.609659 8.69003 0.75Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(AllowIcon);
