import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const FireIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        d="M10.18 0.838379C10.18 0.838379 9.04067 7.72315 6.84666 9.7915C6.60577 7.38916 4.34666 5.33838 4.34666 5.33838C3.17478 9.72705 0.179993 12.4224 0.179993 16.1665C0.179993 20.3032 3.29848 23.7837 7.52374 24.8384C7.11359 24.0942 6.84666 23.1685 6.84666 22.1665C6.84666 17.8247 10.18 14.3384 10.18 14.3384C10.18 14.3384 13.5133 17.8247 13.5133 22.1665C13.5133 23.1685 13.2464 24.0884 12.8362 24.8384C17.0615 23.7837 20.18 20.3032 20.18 16.1665C20.18 13.0728 18.9495 10.4126 17.5237 8.33838C17.5237 8.33838 16.8532 12.3286 14.7633 13.9165C16.749 6.61572 10.18 0.838379 10.18 0.838379Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(FireIcon);
