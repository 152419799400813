import { ReactNode } from 'react';

export type Position = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left';
export type ToastType = 'success' | 'error' | 'warning' | 'default';
export enum Event {
  SHOW = 'show',
  HIDE = 'hide',
}
export interface ToastParams {
  title: string;
  content?: ReactNode;
  delay?: number;
  possition?: Position;
}
export interface ToastData extends ToastParams {
  id: string;
  type?: ToastType;
  open: boolean;
}

type CallBackFunc = (params?: any) => void;
interface ToastService {
  success: (params: ToastParams) => void;
  error: (params: ToastParams) => void;
  warning: (params: ToastParams) => void;
  default: (params: ToastParams) => void;
}

interface EventManager {
  toasts: Map<Event, CallBackFunc[]>;
  on(event: Event.SHOW, callBack: (params: ToastData) => void): EventManager;
  on(event: Event.HIDE, callBack: (id: string) => void): EventManager;
  emit(event: Event.SHOW, params: ToastData): void;
  emit(event: Event.HIDE, params: ToastData): void;
}

export const toastManager: EventManager = {
  toasts: new Map<Event, CallBackFunc[]>(),
  on(event: Event, callBack: CallBackFunc): EventManager {
    this.toasts.has(event) || this.toasts.set(event, []);
    this.toasts.get(event)?.push(callBack);
    return this;
  },
  emit(event: Event, ...args) {
    this.toasts.has(event) &&
      this.toasts.get(event)?.forEach(callBack => {
        const timer = setTimeout(() => {
          callBack(...args);
        }, 100);
        return timer;
      });
  },
};
const toast = {} as ToastService;
toast.success = (toastParams: ToastParams) =>
  toastManager.emit(Event.SHOW, { ...createToast(toastParams), type: 'success' });
toast.error = (toastParams: ToastParams) =>
  toastManager.emit(Event.SHOW, { ...createToast(toastParams), type: 'error' });
toast.warning = (toastParams: ToastParams) =>
  toastManager.emit(Event.SHOW, { ...createToast(toastParams), type: 'warning' });
toast.default = (toastParams: ToastParams) =>
  toastManager.emit(Event.SHOW, { ...createToast(toastParams), type: 'default' });

const createToast = (params: ToastParams) => ({
    ...params,
    possition: params.possition || 'top-right',
    id: generateId(),
  } as ToastData);
const generateId = () => Math.random().toString(36).substr(2, 9);
export default toast;
