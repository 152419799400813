import React, { forwardRef, Ref } from 'react';
import { styled, VariantProps } from '@stitches/react';
import * as SwitchPrimitive from '@radix-ui/react-switch';

const StyledSwitch = styled(SwitchPrimitive.Root, {
  $$size: '16px',
  $$color: '$colors$green9',
  all: 'unset',
  height: '$$size',
  width: 'calc(2 * $$size)',
  backgroundColor: '$gray6',
  borderRadius: 'calc($$size/2)',
  position: 'relative',
  boxShadow: `0 2px 10px gray7`,
  WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  '&:focus': { boxShadow: `0 0 0 2px black`, outline: 'none', border: 'none' },
  '&[data-state="checked"]': { backgroundColor: '$$color' },
  variants: {
    size: {
      sm: {
        $$size: '16px',
      },
      xs: {
        $$size: '12px',
      },
      lg: {
        $$size: '21px',
      },
      xl: {
        $$size: '26px',
      },
    },
    color: {
      success: {
        $$color: '$colors$green9',
      },
      secondary: {
        $$color: '$colors$gray9',
      },
    },
  },
});

const StyledThumb = styled(SwitchPrimitive.Thumb, {
  display: 'block',
  width: '$$size',
  height: '$$size',
  backgroundColor: 'white',
  borderRadius: '$10',
  boxShadow: `0 2px 2px gray7`,
  transition: 'transform 100ms',
  transform: 'translateX(2px)',
  willChange: 'transform',
  '&[data-state="checked"]': { transform: 'translateX($$size)' },
});

let id = 0;

export type SwitchProps = SwitchPrimitive.SwitchProps &
  VariantProps<typeof StyledSwitch> & {
    className?: string;
    onChange?: (e: any) => void;
  };

const Switch = forwardRef(({ className, ...props }: SwitchProps, ref: Ref<HTMLInputElement>) => {
  const cid = props?.id || `switch-${++id}`;

  return (
    <StyledSwitch
      className={className}
      id={cid}
      {...props}
      defaultChecked={props.value as any}
      onCheckedChange={checked => {
        props.onCheckedChange && props.onCheckedChange(checked);
        props.onChange &&
          props.onChange({
            target: {
              value: checked,
            },
          });
      }}
    >
      <StyledThumb ref={ref} />
    </StyledSwitch>
  );
});
Switch.displayName = 'Switch';

export default Switch;
