import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { globalCss, keyframes, styled } from 'configs/stitches';
import { Flex, Image, Provider } from 'components/ui';
import { ToastContainer } from 'components/ui/toast';

import logo from 'static/images/acelogo.svg';

const globalStyles = globalCss({
  html: {
    fontSize: 16,
  },
  body: {
    backgroundColor: '$gray1',
    color: '$white',
    minWidth: 320,
    fontFamily:
      '-apple-system,BlinkMacSystemFont,"Helvetica Neue",Roboto,"Segoe UI",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
  },
  '#root': {
    height: '100%',
  },
  a: {
    color: '$green8',
    textDecoration: 'none',
    '&:hover': {
      color: '$green9',
    },
  },
  '.grecaptcha-badge': {
    display: 'none !important',
  },
});

const App = React.lazy(async () => {
  const res = await Promise.all([import('./App'), new Promise(r => setTimeout(r, 950))]);
  return res[0];
});
const AdminApp = React.lazy(() => import('./AdminApp'));

const AppLoader = () => {
  globalStyles();

  return (
    <HelmetProvider>
      <Helmet>
        <meta
          property="og:description"
          content="A must-have entrance for worldwide gaming multi-chain projects and the best ROI launchpad for crypto investors"
        />
        <meta property="og:image" content="https://acestarter.finance/images/share_cover.jpg" />
        <meta property="og:title" content="DApp | AceStarter - Not Just a Launchpad" />
        <meta property="og:url" content="https://dapp.acestarter.finance/" />
      </Helmet>
      <React.Suspense fallback={<LogoLoading />}>
        <Provider>
          <Routes>
            <Route path="admin/*" element={<AdminApp />} />
            <Route path="*" element={<App />} />
          </Routes>
          <ToastContainer />
        </Provider>
      </React.Suspense>
    </HelmetProvider>
  );
};

export default AppLoader;

const imgLoading = keyframes({
  '0%': {
    height: '0%',
    opacity: 1,
  },
  '95%': {
    height: '100%',
    opacity: 1,
  },
  '100%': {
    height: '100%',
    opacity: 0,
  },
});

const LogoLoading: React.FC<{ className?: string }> = styled(
  ({ className }) => (
    <Flex className={className} align="center" justify="center">
      <div className="loader">
        <Image src={logo} />
        <div className="progress" />
      </div>
    </Flex>
  ),
  {
    height: '100%',
    '.loader': {
      width: 120,
      height: 120,
      position: 'relative',
      img: {
        width: '100%',
        opacity: 0.3,
        filter: 'grayscale(100%)',
      },
      '.progress': {
        bgi: logo,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom',
        position: 'absolute',
        width: '100%',
        height: '100%',
        bottom: 0,
        animation: `${imgLoading} 1s linear infinite`,
      },
    },
  }
);
