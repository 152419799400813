import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const RocketIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        d="M6.10492 6.48126L4.02565 6.6419C3.44708 6.68662 2.93179 6.98985 2.61181 7.47382L0.213853 11.1006C-0.0288161 11.4676 -0.067394 11.927 0.110589 12.3293C0.288619 12.7317 0.654475 13.0121 1.08924 13.0795L2.99305 13.3742C3.4384 11.0109 4.50452 8.65329 6.10492 6.48126Z"
        fill={color}
      />
    <path
        d="M10.6257 21.0069L10.9206 22.9108C10.988 23.3455 11.2684 23.7113 11.6707 23.8893C11.8381 23.9635 12.0153 24 12.1916 24C12.4392 24 12.6851 23.9279 12.8995 23.7861L16.5263 21.3881C17.0103 21.0681 17.3135 20.5528 17.3582 19.9744L17.5189 17.8951C15.3467 19.4955 12.9892 20.5616 10.6257 21.0069Z"
        fill={color}
      />
    <path
        d="M9.90948 19.6873C9.97556 19.6873 10.042 19.6818 10.1084 19.6708C11.0985 19.5053 12.0529 19.2264 12.9633 18.8613L5.13852 11.0365C4.77347 11.9469 4.49456 12.9012 4.329 13.8914C4.26375 14.2817 4.39467 14.6794 4.67447 14.9593L9.04056 19.3253C9.27287 19.5576 9.58642 19.6873 9.90948 19.6873Z"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.972 1.19216C24.072 3.02684 24.001 6.9388 22.0862 10.6407C20.8124 13.1036 18.0073 16.3331 14.321 18.2385C14.3034 18.216 14.2849 18.194 14.2641 18.1733L5.82669 9.73591C5.80597 9.71519 5.78398 9.69653 5.76147 9.67896C7.66683 5.99264 10.8964 3.18761 13.3593 1.91371C16.4584 0.310732 19.7045 0 21.756 0C22.155 0 22.509 0.0117655 22.8078 0.0280779C23.4365 0.0623432 23.9377 0.563527 23.972 1.19216ZM18.9262 5.07371C17.5554 3.70291 15.3252 3.70305 13.9545 5.07371C13.2904 5.73769 12.9247 6.62057 12.9247 7.55961C12.9247 8.49865 13.2904 9.38153 13.9545 10.0455C14.64 10.731 15.54 11.0736 16.4404 11.0736C17.3405 11.0735 18.241 10.7308 18.9262 10.0455C19.5902 9.38153 19.956 8.49865 19.956 7.55961C19.956 6.62057 19.5902 5.73769 18.9262 5.07371Z"
        fill={color}
      />
    <path
        d="M14.9489 9.05114C15.7713 9.87355 17.1096 9.87361 17.932 9.05114C18.3304 8.6527 18.5499 8.12301 18.5499 7.55958C18.5499 6.99614 18.3304 6.46645 17.932 6.06806C17.5208 5.65682 16.9807 5.45123 16.4404 5.45123C15.9003 5.45123 15.3601 5.65682 14.9489 6.06806C14.5505 6.46645 14.3311 6.99614 14.3311 7.55958C14.3311 8.12301 14.5505 8.65274 14.9489 9.05114Z"
        fill={color}
      />
    <path
        d="M0.717552 19.7783C0.897505 19.7783 1.07746 19.7096 1.21471 19.5723L3.51032 17.2767C3.78491 17.0021 3.78491 16.5569 3.51032 16.2823C3.23577 16.0077 2.79055 16.0077 2.51596 16.2823L0.220349 18.5779C-0.0542441 18.8525 -0.0542441 19.2977 0.220349 19.5723C0.357647 19.7096 0.5376 19.7783 0.717552 19.7783Z"
        fill={color}
      />
    <path
        d="M5.61407 18.386C5.33952 18.1115 4.89431 18.1115 4.61971 18.386L0.206189 22.7996C-0.0684043 23.0741 -0.0684043 23.5193 0.206189 23.7939C0.343487 23.9312 0.52344 23.9999 0.703392 23.9999C0.883345 23.9999 1.0633 23.9312 1.20055 23.7939L5.61401 19.3804C5.88865 19.1059 5.88865 18.6607 5.61407 18.386Z"
        fill={color}
      />
    <path
        d="M6.72319 20.4899L4.42763 22.7855C4.15303 23.06 4.15303 23.5052 4.42763 23.7797C4.56492 23.9171 4.74488 23.9857 4.92479 23.9857C5.10468 23.9857 5.28468 23.9171 5.42194 23.7797L7.71755 21.4841C7.99214 21.2096 7.99214 20.7644 7.71755 20.4899C7.443 20.2152 6.99778 20.2152 6.72319 20.4899Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(RocketIcon);
