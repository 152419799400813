import { ethers } from 'ethers';

export const isTestnet = import.meta.env.DEV || location.host !== 'dapp.acestarter.finance';

export const chainverse = {
  apiBaseURL: isTestnet
    ? 'https://beta-faucet-api.dev.chainverse.xyz'
    : 'https://market-api.chainverse.org',
  partnerId: 'FD6124966Q',
  recaptchaKey: '6Let6mYdAAAAAGTgfZl20TLBgobPlMRB8vm00gxj',
};

export const sentry = {
  dsn: 'https://bc25876d5f1546d6bde2272aa879c855@sentry.appota.com/14',
};

export const ipfs = {
  gateways: [
    'https://ipfs-gw.chainverse.org/ipfs/',
    // '/ipfs/',
    // 'https://ipfs.chainverse.org/ipfs/',
    'https://cloudflare-ipfs.com/ipfs/',
    // 'https://ipfs.io/ipfs/',
  ],
};

if (import.meta.env.VITE_PRIMARY_IPFS_GATEWAY) {
  ipfs.gateways.splice(0, 0, `${import.meta.env.VITE_PRIMARY_IPFS_GATEWAY}`);
}

const chainBSC = {
  id: 56,
  name: 'BSC',
  explorer: 'https://bscscan.com/',
  rpcUrls: [
    'https://bsc-dataseed.binance.org/',
    // 'https://bsc-dataseed1.defibit.io/',
    'https://bsc-dataseed1.ninicoin.io/',
    // 'https://bsc.dmm.exchange/v1/mainnet/geth?appId=prod-dmm-interface',
  ],
  contracts: {
    routerAddress: '0xc7Fb7cEbdD8c368820A83a0af155a0f529828388',
    shopAddress: '0xd63d06d173eecbb5bd203ef309aba8918fd93b8a',
    vcroundAddress: '0x3E1B442Ef98bCB5afaF68C37D78aA78747442BAA',
    avaAceAddress: '0x042325a7Dd3342e72eA3d6dFe8E6507a2235ACD5',
    chainverseMarket: '',
  } as { [key: string]: string },
  tokens: {
    ASTAR: {
      address: '0x9eeDDb9DA3BCBfdcFBF075441A9e14c6a8899999',
      symbol: 'ASTAR',
      name: 'ASTAR',
      decimals: 18,
    },
    APOINT: {
      address: '0x0000000000000000000000000000000000000001',
      symbol: 'APOINT',
      name: 'APOINT',
      decimals: 18,
    },
    BUSD: {
      name: 'BUSD',
      symbol: 'BUSD',
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
    },
    USDT: {
      name: 'USDT',
      symbol: 'USDT',
      address: '0x55d398326f99059fF775485246999027B3197955',
      decimals: 18,
    },
    BNB: {
      name: 'BNB',
      symbol: 'BNB',
      address: ethers.constants.AddressZero,
      decimals: 18,
    },
  } as { [key: string]: Token },
};

export const chains: { [k: number]: typeof chainBSC | undefined } = {
  56: chainBSC,
  97: {
    id: 97,
    name: 'BSC Testnet',
    explorer: 'https://testnet.bscscan.com/',
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.binance.org:8545/',
      // 'https://data-seed-prebsc-2-s1.binance.org:8545/',
      // 'https://data-seed-prebsc-1-s2.binance.org:8545/',
      // 'https://data-seed-prebsc-2-s2.binance.org:8545/',
      'https://data-seed-prebsc-1-s3.binance.org:8545/',
      // 'https://data-seed-prebsc-2-s3.binance.org:8545/',
    ],
    contracts: {
      routerAddress: '0x69556293e05E9A3a2c91B5A422E031b9C58bCdEE',
      shopAddress: '0x686f22b15d5d185e2b1ea6241601488248e3ffc5',
      vcroundAddress: '0xE24e5012EA7FD37BC966D5108cf786E791197710',
      avaAceAddress: '0x9f2B0122a1230842BBD4A6Adf709125862546d01',
      chainverseMarket: '0x2ccA92F66BeA2A7fA2119B75F3e5CB698C252564',
    },
    tokens: {
      ASTAR: {
        address: '0x5Cfad1F9Bb560fC5778c78CB208C3c2f67D88C64',
        symbol: 'ASTAR',
        name: 'ASTAR',
        decimals: 18,
      },
      APOINT: {
        address: '0x0000000000000000000000000000000000000001',
        symbol: 'APOINT',
        name: 'APOINT',
        decimals: 18,
      },
      BUSD: {
        name: 'BUSD',
        symbol: 'BUSD',
        address: '0x8301F2213c0eeD49a7E28Ae4c3e91722919B8B47',
        decimals: 18,
      },
      USDT: {
        name: 'USDT',
        symbol: 'USDT',
        address: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
        decimals: 18,
      },
      BNB: {
        name: 'BNB',
        symbol: 'BNB',
        address: ethers.constants.AddressZero,
        decimals: 18,
      },
    },
  },
  80001: {
    id: 80001,
    name: 'Poygon Mumbai',
    rpcUrls: ['https://rpc-mumbai.matic.today'],
    explorer: 'https://mumbai.polygonscan.com/',
    tokens: {
      MATIC: {
        address: ethers.constants.AddressZero,
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      ASTAR: {
        address: '0x359204B7Fa11F877A22B0e6103353BA62D1B0D54',
        name: 'ASTAR',
        symbol: 'ASTAR',
        decimals: 18,
      },
    },
    contracts: {
      routerAddress: '0x1c9cfa5348617cedc12577B70C9a122A7593cEe2',
    },
  },
  137: {
    id: 137,
    name: 'Polygon',
    rpcUrls: ['https://polygon-rpc.com/'],
    explorer: 'https://polygonscan.com/',
    tokens: {
      MATIC: {
        address: ethers.constants.AddressZero,
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      // ASTAR: {
      //   address: '',
      //   name: 'ASTAR',
      //   symbol: 'ASTAR',
      //   decimals: 18,
      // },
    },
    contracts: {
      routerAddress: '',
    },
  },
  5: {
    id: 5,
    name: 'Goerli Testnet',
    rpcUrls: [
      // 'https://goerli.prylabs.net',
      'https://goerli.infura.io/v3/4d37746e2ecc4615bfed4d8b02b81104',
    ],
    explorer: 'https://goerli.etherscan.io',
    contracts: {
      routerAddress: '0xA65b2f79F3F908F4178AF840c15f0e4555637abb',
    },
    tokens: {
      MATIC: {
        address: ethers.constants.AddressZero,
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      ASTAR: {
        address: '0xcFEdE98EFa3329725D58f549228e8C5F0fccA54E',
        name: 'ASTAR',
        symbol: 'ASTAR',
        decimals: 18,
      },
      BUSD: {
        address: '0xaa16CEdFF0B93B03D6537f99c812dC69262E3743',
        name: 'BUSD',
        symbol: 'BUSD',
        decimals: 18,
      },
    },
  },
  1: {
    id: 1,
    name: 'Ethereum',
    rpcUrls: ['https://mainnet.infura.io/v3/4d37746e2ecc4615bfed4d8b02b81104'],
    explorer: 'https://etherscan.io',
    contracts: {
      routerAddress: '0x3E1B442Ef98bCB5afaF68C37D78aA78747442BAA',
    },
    tokens: {
      MATIC: {
        address: ethers.constants.AddressZero,
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18,
      },
      ASTAR: {
        address: '',
        name: 'ASTAR',
        symbol: 'ASTAR',
        decimals: 18,
      },
      BUSD: {
        address: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
        name: 'BUSD',
        symbol: 'BUSD',
        decimals: 18,
      },
    },
  },
};

export const mainChain = isTestnet ? chains[97]! : chains[56]!;

export const supportedChains = isTestnet ? [97, 80001, 5] : [56, 137, 1];

export const bscscanBaseURL = isTestnet
  ? `https://api-testnet.bscscan.com/api`
  : `https://api.bscscan.com/api`;

export const tokens = mainChain.tokens;

export const google = {
  oauthClientId: '61516405736-dj7tc9j94b4omn66260mpu17g0oaquhm.apps.googleusercontent.com',
  recaptchaKey: '6LccSSEeAAAAAPI__OY1rcu2geejz0PLUHNaiGFe',
};

export const api = {
  baseUrl: (import.meta.env.VITE_API_BASE_URL as string) || '/api/v1',
};
