import React, { ComponentProps, useState } from 'react';
import { Square2StackIcon } from '@heroicons/react/24/solid';

import { styled } from 'configs/stitches';
import { makeError } from 'utils';

import { Flex, Text, Button, Tooltip } from '.';

interface CopyProps {
  text: string;
  onlyIcon?: boolean;
}

const CopyButton: React.FC<CopyProps & ComponentProps<typeof Flex>> = ({
  text,
  onlyIcon,
  children,
  ...props
}) => {
  const [copyStatus, setCopyStatus] = useState<{
    copied?: boolean;
    loading?: boolean;
    error?: Error;
  }>({
    copied: false,
  });

  const copyToClipboard = async () => {
    try {
      setCopyStatus({ loading: true });
      await navigator.clipboard.writeText(text);
      setCopyStatus({ copied: true });
    } catch (e) {
      console.log(e);
      setCopyStatus({ error: makeError(e) });
    }
    setTimeout(() => {
      setCopyStatus({ copied: false });
    }, 2000);
  };

  return (
    <Flex align="center" gap="1" {...props}>
      {!onlyIcon && (children || <Text>{text}</Text>)}
      <Tooltip
        content={copyStatus.error?.message || 'Copied'}
        delayDuration={1}
        open={copyStatus.copied || !!copyStatus.error}
      >
        <Button className="btn-copy" color="secondary" variant="link" onClick={copyToClipboard}>
          <Square2StackIcon width="1.5em" />
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default styled(CopyButton, {
  width: 'max-content',
  '.btn-copy': {
    padding: 2,
    borderRadius: '50%',
  },
  variants: {
    size: {
      sm: {
        fontSize: '$sm',
      },
    },
  },
});
