import React from 'react';

import { styled, keyframes } from 'configs/stitches';

import { Box } from '.';

const pulse = keyframes({
  '0%': { backgroundPosition: '100% 50%' },
  '100%': { backgroundPosition: '0 50%' },
});

const Item = styled('div', {
  backgroundColor: '$gray4',
  animationName: `${pulse}`,
  animationDuration: '1.2s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'ease-in-out',
  linearGradient: '90deg, $grayA5 20%, $grayA6 35%, $grayA5 40%',
  backgroundSize: '400% 100%',

  variants: {
    type: {
      avatar: {
        width: 64,
        height: 64,
        borderRadius: '$10',
        marginBottom: '12px',
      },
      text: {
        height: '6px',
        marginBottom: '6px',
      },
      heading: {
        height: '20px',
        marginBottom: '12px',
        width: '50%',
      },
      image: {
        borderRadius: '$3',
        height: '100px',
        marginBottom: '12px',
      },
      button: {
        borderRadius: '$3',
        height: '$lg',
        width: 'calc(3 * $lg)',
      },
    },
  },
  defaultVariants: {
    type: 'text',
  },
});

type ItemProps = Omit<React.ComponentProps<typeof Item>, 'type'>;

const Paragraph = (props: ItemProps) => (
  <Box css={{ marginBottom: '$2' }} {...props}>
    <Item />
    <Item css={{ width: '90%' }} />
    <Item css={{ width: '80%' }} />
    <Item css={{ width: '50%' }} />
  </Box>
);
const Card = (props: ItemProps) => (
  <Box
    css={{
      backgroundColor: '$gray1',
      padding: '$1',
      borderRadius: '$3',
    }}
    {...props}
  >
    <Skeleton.Image css={{ aspectRatio: '1/1', width: '100%', height: 'auto' }} />
    <Box css={{ padding: '$1' }}>
      <Skeleton.Heading css={{ py: '$2', borderRadius: '$2' }} />
      <Skeleton.Item css={{ width: 100, my: '$2' }} />
      <Skeleton.Button css={{ width: '100%' }} />
    </Box>
  </Box>
);

export const Skeleton = {
  Item,
  Heading: (props: ItemProps) => <Item {...props} type="heading" />,
  Avatar: (props: ItemProps) => <Item {...props} type="avatar" />,
  Image: (props: ItemProps) => <Item {...props} type="image" />,
  Button: (props: ItemProps) => <Item {...props} type="button" />,
  Paragraph,
  Card,
};
