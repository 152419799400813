import React from 'react';
import { Root, Indicator, ProgressProps } from '@radix-ui/react-progress';

import { styled } from 'configs/stitches';
import { Flex, Box, Text } from 'components/ui';

export const Progress = styled(
  ({
    value,
    max,
    className,
    noLabel,
  }: ProgressProps & { noLabel?: boolean; className?: string }) => {
    const percent = (max ? (value || 0) / max : 0) * 100;

    return (
      <Box className={className}>
        <Root
          className="root"
          max={max}
          value={Math.min(value || 0, max || 0)}
          title={`${percent}%`}
        >
          <Indicator className="indicator" style={{ width: `${percent}%` }} />
        </Root>
        {!noLabel && (
          <Flex className="labels" justify="between">
            <Text size="sm">{Math.round(value || 0)}%</Text>
            <Text size="sm">100%</Text>
          </Flex>
        )}
      </Box>
    );
  },
  {
    '.root': {
      backgroundColor: '$gray1',
      borderRadius: 999,
      overflow: 'hidden',
      height: 10,
      '.indicator': {
        height: '100%',
        borderRadius: 999,
        backgroundColor: '$green9',
        transition: 'width 0.3s',
      },
    },
    '.labels': {
      marginTop: '$1',
    },
    variants: {
      size: {
        sm: {},
        lg: {},
      },
    },
  }
);
