import React, { forwardRef } from 'react';

import { type IconProps } from '.';

const FarmIcon = ({ color = 'currentColor', ...props }: IconProps, forwardedRef) => (
  <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16.766C0 13.6594 2.5184 11.141 5.625 11.141C8.7316 11.141 11.25 13.6594 11.25 16.766C11.25 19.8726 8.7316 22.391 5.625 22.391C2.5184 22.391 0 19.8726 0 16.766ZM3.516 16.766C3.516 17.9308 4.46023 18.875 5.625 18.875C6.78908 18.8733 7.73235 17.9301 7.734 16.766C7.734 15.6012 6.78977 14.657 5.625 14.657C4.46023 14.657 3.516 15.6012 3.516 16.766Z"
        fill={color}
      />
    <path
        d="M5.62212 16.062C5.23552 16.062 4.92212 16.3754 4.92212 16.762C4.92212 17.1486 5.23552 17.462 5.62212 17.462C6.00872 17.462 6.32212 17.1486 6.32212 16.762C6.32212 16.3754 6.00872 16.062 5.62212 16.062V16.062Z"
        fill={color}
      />
    <path
        d="M18.375 6.21801C18.375 5.83141 18.6884 5.51801 19.075 5.51801V4.10901C17.9121 4.11231 16.9706 5.05509 16.969 6.21801V8.32701H18.375V6.21801Z"
        fill={color}
      />
    <path
        d="M14.9109 15.359C15.6562 14.0668 16.8846 13.124 18.3256 12.7381C19.7665 12.3523 21.3017 12.5551 22.5929 13.302V12.547C22.5907 10.9949 21.333 9.73717 19.7809 9.73497H10.2809C7.18774 7.68226 3.11581 7.89463 0.25293 10.258L1.15293 11.342C3.05683 9.77175 5.64452 9.31451 7.97124 10.1372C10.298 10.9598 12.0231 12.942 12.5169 15.36L14.9109 15.359Z"
        fill={color}
      />
    <path
        d="M14.334 16.766H12.656V18.875H14.2C14.1115 18.1698 14.157 17.4543 14.334 16.766Z"
        fill={color}
      />
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8843 16.5575C16.5373 14.9809 18.0757 13.953 19.7821 13.953C22.1122 13.953 24.0011 15.8419 24.0011 18.172C24.0011 19.8784 22.9732 21.4168 21.3967 22.0699C19.8201 22.7229 18.0055 22.3619 16.7988 21.1553C15.5922 19.9487 15.2312 18.134 15.8843 16.5575ZM19.0821 18.175C19.0821 18.5616 19.3955 18.875 19.7821 18.875C20.1687 18.875 20.4821 18.5616 20.4821 18.175C20.4821 17.7884 20.1687 17.475 19.7821 17.475C19.3955 17.475 19.0821 17.7884 19.0821 18.175Z"
        fill={color}
      />
    <path
        d="M3.51489 7.151C5.96906 6.61106 8.53684 7.03228 10.6899 8.328H13.4289L12.1369 3.406H13.3589V2H2.10889V3.406H3.51489V7.151Z"
        fill={color}
      />
  </svg>
  );

export default forwardRef(FarmIcon);
